import { getTenantList } from "@/services/TenantService";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function UpdataTenant({ open, setOpen }) {
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [tenant, setTenant] = useState("");
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    getTenantList({ pageNumber: 1, pageSize: 10000 }).then((response) => {
      if (response?.data?.code === "LVLI0000") {
        setRecords(response?.data?.data?.objects);
      } else if (response?.data?.code === "LVLE0054") {
        setRecords([]);
      }
    });
  };

  const handlerTenant = () => {};

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        open={open}
        sx={{
          maxWidth: { xs: 1400, lg: 2000 },
          mb: "200px",
        }}
        overflowX={"auto"}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontFamily: "Myriad Pro", minWidth: 300 }}
          fontSize={26}
          fontWeight={700}
        >
          {t("LVL0034")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            item
            xs={10}
            mt={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ minWidth: 400 }}>
              <FormControl fullWidth={true}>
                {/* <InputLabel sx={{ marginTop: "-10px", fontSize: "14px" }}>
                  {t("LVLDAC0038")}
                </InputLabel> */}
                <Select
                  id="demo-simple-select"
                  value={tenant}
                  label="Store Name"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setTenant(selectedValue);
                    localStorage.setItem("TENANT_ID", e.target.value?.tenantId);
                    window.location.reload();
                  }}
                >
                  {records?.map((item) => (
                    <MenuItem key={item?.id} value={item}>
                      {item?.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button autoFocus onClick={handlerTenant}>
            Confirm
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default UpdataTenant;
