import React from "react";
import { useDropzone } from "react-dropzone";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
const FileUpload = ({
  onUpload,
  accept = {
    "image/jpeg": [".jpeg", ".png"],
    "application/pdf": [".pdf", ".doc"],
  },
  multiple = false,
  children,
  dropCom = null,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const onDrop = async (acceptedFiles) => {
    if (multiple && acceptedFiles && acceptedFiles.length > 1) {
      enqueueSnackbar(t("subscription.singleFile"), {
        variant: "error",
      });
    } else {
      onUpload(acceptedFiles);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: accept,
    onError: (e) => {
      enqueueSnackbar(e, {
        variant: "error",
      });
    },
  });
  const InputProps = getInputProps();
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input
        {...InputProps}
        style={{
          display: "none",
        }}
      />
      {isDragActive ? (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {dropCom ? dropCom : <div>{t("subscription.dropTip")}</div>}
        </Grid>
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default FileUpload;
