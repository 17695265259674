import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = () => {
    props.onConfirm();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        {props.title && (
          <DialogTitle>
            <Grid display="flex">
              <Grid flexGrow={1}>
                <span style={{ fontSize: "1rem" }}>{props.title}</span>
              </Grid>
            </Grid>
          </DialogTitle>
        )}

        <DialogContent>
          <Grid display="flex" p={1}>
            {props.text}
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "16px",
              marginTop: "20px",
              color: "#145dd3",
            }}
          >
            <Grid onClick={handleSubmit} spacing={3}>
              {t("LVLDB0011")}
            </Grid>

            <Grid
              onClick={handleClose}
              spacing={3}
              style={{ marginLeft: "30px" }}
            >
              {t("LVLDB0012")}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
