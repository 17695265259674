import React, { useEffect, useState } from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import CommonUtil from "../util/CommonUtils";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
export default function CustomInput(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.regex === "none" && props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleAlphaNumeric(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "password":
          handlePassword(e);
          break;
        case "code":
          handleCode(e);
          break;
        case "mobile":
          handleMobile(e);
          break;
        case "postcode":
          handlePostCode(e);
          break;
        case "alpha-numeric-underscore":
          handleCodeUnderscore(e);
          break;
        case "alpha-numeric-space":
          handleAlphaNumericSpace(e);
          break;
        case "numericWithoutDot":
          handleNumericWithoutDot(e);
          break;
        case "Devicenumber":
          handleDeviceNumber(e);
          break;
        default:
          props.handleChange(e);
      }
    }
  };

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0006"));
      //
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts alphabets, numerics and underscore"));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(
        t("Only accepts alphabets, numerics , space and underscore")
      );
    }
  };
  const handleNumericWithoutDot = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText("Only accepts numerics");
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0004"));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0002"));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0005"));
    }
  };

  const handleDeviceNumber = (e) => {
    props.handleChange(e);
    if (CommonUtil.isDeviceNumber(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0031"));
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0003"));
    }
  };
  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0005"));
    }
  };

  const handlePassword = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("LVL0006"));
    }
  };

  return (
    <Box letiant="standard" style={{ width: "100%" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        style={{ paddingLeft: "0px", fontSize: "14px" }}
      >
        {props.label}
        {props.required ? <span style={{ color: "red" }}>*</span> : null}
      </InputLabel>
      <TextField
        {...props}
        autoComplete="new-password"
        fullWidth={true}
        sx={{
          "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
            fontSize: "14px",
          },
          "& .MuiInputBase-root ": {
            lineHeight: "0.6rem",
          },
        }}
        type={props.type}
        value={props.value}
        helperText={helperText}
        error={error}
        label={""}
        onChange={(e) => handleChange(e)}
      />
    </Box>
  );
}

CustomInput.defaultProps = {
  validation: "none",
  regex: "none",
};
