import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPackageList } from "@/services/subscription";
import ListLayout from "@/components/ListLayout";

function Subscription(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    loadPack();
  }, []);

  let [list, setList] = useState([]);
  const loadPack = () => {
    getPackageList()
      .then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setList(res.data?.data);
        } else {
          setList([]);
        }
      })
      .catch(() => {
        setList([]);
      });
  };

  const bayPackage = (id) => {
    navigate(`/subscription/add`, {
      state: {
        id: id,
        type: "add",
      },
    });
  };

  return (
    <ListLayout
      navigateBack={false}
      title={t("subscription.subscription")}
      sx={{
        paddingLeft: "30px",
      }}
    >
      <Grid container spacing={4}>
        {list?.map((item, packageIndex) => {
          return (
            <Grid
              key={item.id || packageIndex}
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
            >
              <Grid
                sx={{
                  border: "2px solid #65B247",
                  borderRadius: "10px",
                  minHeight: "300px",
                  bgcolor: "#FFF",
                }}
              >
                <Grid
                  sx={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.5rem",
                    fontWeight: 500,
                    color: "#474B4F",
                  }}
                >
                  {t(`subscription.${item.packageI18n}`)}
                </Grid>

                {item.id === "1" && (
                  <Grid
                    sx={{
                      padding: "0px 30px",
                      minHeight: "140px",
                    }}
                  >
                    <Grid
                      sx={{
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.7rem",
                          fontWeight: "bold",
                        }}
                      >
                        {t(`subscription.free`)}
                      </Grid>
                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.4rem",
                        }}
                      >
                        {t(`subscription.freeTip`)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {item.id === "2" && (
                  <Grid
                    sx={{
                      padding: "0px 30px",
                      minHeight: "140px",
                    }}
                  >
                    <Grid
                      sx={{
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.7rem",
                          fontWeight: "bold",
                        }}
                      >
                        {t(`subscription.US$10`)}
                      </Grid>

                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.4rem",
                        }}
                      >
                        {t(`subscription.basic_package_tip`)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {item.id === "3" && (
                  <Grid
                    sx={{
                      padding: "0px 30px",
                      minHeight: "140px",
                    }}
                  >
                    <Grid
                      sx={{
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.7rem",
                          fontWeight: "bold",
                        }}
                      >
                        {t(`subscription.US$10`)}
                      </Grid>

                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.4rem",
                        }}
                      >
                        {t(`subscription.basic_package_tip`)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {item.id === "4" && (
                  <Grid
                    sx={{
                      padding: "0px 30px",
                      minHeight: "140px",
                      fontWeight: "bold",
                    }}
                  >
                    <Grid
                      sx={{
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t(`subscription.US$10`)}
                      </Grid>

                      <Grid
                        sx={{
                          color: "#474B4F",
                          fontSize: "0.4rem",
                        }}
                      >
                        {t(`subscription.basic_package_tip`)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  sx={{
                    padding: "0px 30px",
                    marginTop: "10px",
                  }}
                >
                  <Grid
                    onClick={() => {
                      bayPackage(item.id);
                    }}
                    sx={{
                      fontSize: "24px",
                      borderRadius: "10px",
                      padding: "8px",
                      border: "2px solid #7ac143",
                      color: "#474B4F",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#7ac143",
                        color: "#ffffff",
                      },
                    }}
                  >
                    {item.id === "4"
                      ? t(`subscription.comingSoon`)
                      : t(`subscription.getStarted`)}
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    padding: "0px 30px",
                  }}
                >
                  <Grid
                    sx={{
                      fontSize: "18px",
                      color: "#474B4F",
                      marginTop: "40px",
                      marginBottom: "10px",
                      fontWeight: 600,
                    }}
                  >
                    {t(`subscription.includes`)}:
                  </Grid>
                  <Grid>
                    {item.packageMenuResponses?.map((menuItem, menuIndex) => {
                      return (
                        <Grid
                          sx={{
                            margin: "10px 0px",
                          }}
                          key={menuItem.id || menuIndex}
                        >
                          <Grid
                            sx={{
                              color: "#474B4F",
                              fontSize: "14px",
                              margin: "10px 0px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              style={{
                                height: "2px",
                                width: "2px",
                                background: "gray",
                                marginRight: "5px",
                              }}
                            ></Grid>
                            {menuItem?.name}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </ListLayout>
  );
}

export default Subscription;
