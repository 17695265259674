import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable.jsx";
import IconHandaler from "../../components/IconHandaler.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../components/ConfirmModel.jsx";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import { Box, Grid, TextField, Tooltip, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ListLayout from "../../components/ListLayout.jsx";
import { useNavigate } from "react-router-dom";
import {
  REACT_ADD_AUTHORITATION,
  REACT_EDIT_AUTHORITATION,
  REACT_VIEW_AUTHORITATION,
} from "@/router/ReactEndPoints";
import { getRoleList, deleteRole } from "../../services/authoritation.js";
import CommonUtil from "../../util/CommonUtils.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessControl from "../../components/AccessControl.jsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export default function Authoritation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    getRoleList(filters).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRecords(res?.data?.data.objects);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
  };

  const delteItem = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("PCS83")}
            onConfirm={() => {
              deleteRole(e).then((response) => {
                if (response.data.code === "LVLE0000") {
                  enqueueSnackbar(response?.data?.message, {
                    variant: "error",
                  });
                  return;
                }

                if (response.data.code === "LVLI0000") {
                  enqueueSnackbar(response?.data?.message, {
                    variant: "success",
                  });
                  loadData();
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      sessionStorage.setItem("viewOutlet", JSON.stringify(data.row));
      navigate(REACT_VIEW_AUTHORITATION, {
        state: data.row,
      });
    } else if (action === "Edit") {
      sessionStorage.setItem("editOutlet", JSON.stringify(data.row));
      navigate(REACT_EDIT_AUTHORITATION, {
        state: data.row,
      });
    } else if (action === "Delete") {
      delteItem(data.id);
    }
  };
  const columns = [
    {
      field: "name",
      headerName: t("LVLRC0023"),
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },

    {
      headerName: t("LVLRC0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandaler>
          <AccessControl authorization={"1050"}>
            <Tooltip title={t("LVLRC0028")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>
          </AccessControl>

          <AccessControl authorization={"1052"}>
            <Tooltip title={t("LVLRC0029")} arrow sx={{ pl: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          </AccessControl>

          <AccessControl authorization={"1053"}>
            <Tooltip title={t("LVLDB0022")} arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Delete", e)}
              />
            </Tooltip>
          </AccessControl>
        </IconHandaler>
      ),
    },
  ];

  const refreshClick = () => {
    loadData();
    enqueueSnackbar(t("LVLWS0005"), {
      variant: "success",
    });
  };

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleSubmit = () => {
    let params = {
      pageNumber: 1,
      pageSize: filters.pageSize,
      name: storeName,
    };
    getRoleList(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRecords(res?.data?.data.objects);
        setTotalRecords(res?.data?.data?.totalCount);
      } else {
        setRecords([]);
        setTotalRecords(0);
      }
    });
  };

  const handleReset = () => {
    setStoreName("");
    loadData();
  };
  const getRowId = (data) => data.id;
  return (
    <ListLayout navigateBack={false} title={t("MENU14")}>
      <Grid
        display={"flex"}
        sx={{
          background: "#FFF",
          height: "100px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLRC0037")}
            placeholder={t("LVLRC0038")}
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          ></TextField>
        </Box>

        <Box sx={{ margin: "35px 35px" }}>
          <Button
            variant="contained"
            sx={{ marginLeft: 2, p: 0.5 }}
            style={{
              height: "28px",
              fontSize: "14px",
              height: "28px",
              fontWeight: 400,
              textTransform: "none",
            }}
            onClick={handleSubmit}
          >
            {t("LVLGF0009")}
          </Button>

          <Button
            style={{
              height: "28px",
              fontSize: "14px",
              height: "28px",
              fontWeight: 400,
              textTransform: "none",
            }}
            sx={{ marginLeft: 1, p: 0.5 }}
            variant="outlined"
            onClick={handleReset}
          >
            {t("LVLGF0008")}
          </Button>
        </Box>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        <AccessControl authorization={"1051"}>
          <Box
            item
            pr={2}
            onClick={() => {
              navigate(REACT_ADD_AUTHORITATION);
            }}
          >
            <AddIcon width={"35"} height={"35"} className="pointer" />
          </Box>
        </AccessControl>
        <Box item pr={2}>
          <RefreshIcon
            width={"35"}
            height={"35"}
            className="pointer"
            onClick={refreshClick}
          />
        </Box>
      </Grid>

      <DataTable
        columns={columns}
        rows={records || []}
        getRowId={getRowId}
        onSelection={() => console.log()}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />
    </ListLayout>
  );
}
