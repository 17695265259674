export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:hover": {
            border: "1px solid #d9d9d9",
          },
          fontSize: 14,
          padding: "0px 14px",
          minHeight: "32px",
          display: "flex",
          alignItems: "center",
        },
        outlined: {
          border: "1px solid #d9d9d9",
          "&:hover": {
            border: "1px solid #d9d9d9",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          //   top:'-10px'
        },
      },
    },
  };
}
