import React from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { Grid, Box, Typography, Button, Autocomplete } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  REACT_DASHBOARD,
  REACT_VIEW_USER_PROFILE,
  REACT_PEOPLECOUNTING_SUMMARY,
} from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import ViewPage from "../../components/ViewPage";
import CommonUtil from "../../util/CommonUtils";
import { InputLabel, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import {
  getAllCities,
  getAllCountries,
  getAllStates,
} from "../../services/LocationService";
import { selectCity, selectCountry, selectState } from "@/services/Location";
export default function UpdateUserProfile() {
  var data = localStorage.getItem("USER");
  var parseData = JSON.parse(data);
  // console.log(data);
  // console.log(parseData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [countries, setCountries] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    addressLine1: "",
    phoneCountryCode: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    setPayload({
      ...payload,
      state: "",
      city: "",
    });
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setPayload({
      ...payload,
      city: "",
    });
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({
        stateId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  useEffect(() => {
    let user = CommonUtil.decodeToken();
    if (user) {
      getUser(user?.id, true);
    }
  }, []);

  const getUser = async (id, loadProfileImage) => {
    await UserService.getUserDetails(id, loadProfileImage).then((res) => {
      setPayload({
        ...payload,
        ...res?.data?.data,
      });
      let phonWithCounrtyCode = res?.data?.data?.phone;
      if (phonWithCounrtyCode) {
        var mobile = phonWithCounrtyCode.split("-");
        // console.log(mobile[0]);
        // console.log(mobile[1]);

        if (mobile) {
          setCountryCode(mobile[0]);
          setPhoneNumber(mobile[1]);
          setPayload({
            ...payload,
            phone: mobile[1],
            phoneCountryCode: mobile[0],
            ...res?.data?.data,
          });
        }
      }
    });
  };

  const updateUser = async (id, payload) => {
    if (validateForm()) {
      var request = {
        ...payload,
        phone: phoneNumber,
        phoneCountryCode: countryCode,
      };

      await UserService.updateUser(id, request).then((res) => {
        if (res?.data?.code === "UASI0002" && res?.data?.data) {
          enqueueSnackbar(res?.data?.message, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_PEOPLECOUNTING_SUMMARY);
        }
        console.log(res?.data?.data);
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleSubmit = (event) => {
    let user = CommonUtil.decodeToken();
    updateUser(user.id, payload);
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: t("LVL0001"),
      });
      return false;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: "Country Code is required.",
        });
        return false;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("LVL0008"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("LVLRCP010"),
        });
        return false;
      }
    }
    return true;
  };

  return (
    <RightViewLayout navigateBack={"-1"} title={t("LVL0022")}>
      <ViewPage>
        <Box>
          {/* <Typography variant="fieldLabel">First Name:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRCP014")}
            name="firstName"
            size="small"
            value={payload.firstName}
            handleChange={handleChange}
            error={error.firstName}
            helperText={error.firstName}
          />
        </Grid>

        <Box mt={2}>
          {/* <Typography variant="fieldLabel">Last Name:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLRCP015")}
            name="lastName"
            size="small"
            value={payload.lastName}
            handleChange={handleChange}
            error={error.lastName}
          />
        </Grid>

        <Box mt={2}>
          {/* <Typography variant="fieldLabel">Email:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLRCP004")}
            name="email"
            size="small"
            value={payload.email}
            handleChange={handleChange}
            error={error.email}
            disabled
          />
        </Grid>

        <Grid item md={6} xs={12} mt={2}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px", fontSize: "14px" }}
          >
            {t("LVLRCP005")}
          </InputLabel>
          <CustomePhoneFiled
            error={error.phone}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCode);
              setError({ ...error, phone: "" });
            }}
            countryCode={countryCode}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size="small"
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, phone: "" });
            }}
            label={t("LVLRCP005")}
          />
        </Grid>

        <Grid item md={6} xs={12} mt={1}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px", fontSize: "14px" }}
          >
            {t("LVLRC0007")}
          </InputLabel>
          <Autocomplete
            options={countries}
            value={country}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputValue={payload.country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({ ...payload, country: v ? v.name : "" });
              setError({ ...error, country: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                error={!CommonUtil.isEmptyString(error.country)}
                helperText={error.country}
                InputLabelProps={{
                  style: { fontSize: 16 }, // 调整标签文字大小
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 16 }, // 调整输入框文字大小
                }}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Box mt={2}>
          {/* <Typography variant="fieldLabel">State:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px", fontSize: "14px" }}
          >
            {t("LVLRC0008")}
          </InputLabel>
          <Autocomplete
            error={error.state}
            helperText={error.state}
            options={stateList}
            value={state}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputValue={payload.state}
            onChange={(e, v) => {
              setState(v);
              setPayload({ ...payload, state: v ? v.name : "" });
              setError({ ...error, state: false });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                error={error.state}
                helperText={error.state}
                InputLabelProps={{
                  style: { fontSize: 16 }, // 调整标签文字大小
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 16 }, // 调整输入框文字大小
                }}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Box mt={2}>
          {/* <Typography variant="fieldLabel">City:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px", fontSize: "14px" }}
          >
            {t("LVLRC0009")}
          </InputLabel>
          <Autocomplete
            error={error.city}
            inputValue={payload.city}
            helperText={error.city}
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setPayload({ ...payload, city: v ? v.name : "" });
              setError({ ...error, city: false });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                error={error.city}
                helperText={error.city}
                InputLabelProps={{
                  style: { fontSize: 16 }, // 调整标签文字大小
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 16 }, // 调整输入框文字大小
                }}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Box mt={2}>
          {/* <Typography variant="fieldLabel">Address:</Typography> */}
        </Box>

        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLDAC0022")}
            name="addressLine1"
            size="small"
            value={payload.addressLine1}
            handleChange={handleChange}
            error={error.address}
          />
        </Grid>
        <Box item pt={2}>
          <Button
            variant="contained"
            size="large"
            className="text-transform-none"
            onClick={handleSubmit}
          >
            {t("LVL0013")}
          </Button>
        </Box>
      </ViewPage>
    </RightViewLayout>
  );
}
