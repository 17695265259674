import React, { useEffect, useState, useMemo } from "react";
import DataTable from "@/components/DataTable.jsx";
import RingLoader from "react-spinners/RingLoader";
import { getClinetsList } from "@/services/CompanyService.js";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CommonUtil from "@/util/CommonUtils.js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  TextField,
  Tooltip,
  Radio,
} from "@mui/material";

function CreateCustomer(props) {
  const { open, setOpen, addFormik } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [storeName, setStoreName] = useState("");
  const [clientID, setClinetID] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    name: "",
    code: "",
    country: "",
    operator: "",
  });

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
    name: "",
    code: "",
    country: "",
    operator: "",
  };
  const getRowId = (data) => data.id;
  /**
   * @method handleChange
   * 根据id 保存选中行id
   */
  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (event, rowId) => {
    setSelectedValue(rowId);
  };

  /**
   * @method renderRadioColumn
   * 单选框 渲染方法
   */
  const renderRadioColumn = (params) => {
    const rowId = getRowId(params.row);

    return (
      <Radio
        checked={selectedValue === rowId}
        onChange={(event) => handleChange(event, rowId)}
        value={rowId}
        name="radio-buttons"
        inputProps={{ "aria-label": rowId }}
      />
    );
  };

  const columns = useMemo(() => {
    const columns = [
      {
        field: "clientId",
        headerName: "",
        width: 50,
        sortable: false,
        renderCell: renderRadioColumn,
      },
      {
        field: "name",
        headerName: t("LVLRC0023"),
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },

      {
        field: "code",
        headerName: t("LVLRC0025"),
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      },

      {
        field: "email",
        headerName: t("LVLRC0004"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.email} arrow placement="bottom">
            <span> {CommonUtil.formatLongText(e.row.email)}</span>
          </Tooltip>
        ),
      },

      {
        field: "mobile",
        headerName: t("LVLRC0005"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          const containsNumericDigits = /\d/.test(e.row.mobile);
          const phoneToDisplay = containsNumericDigits ? e.row.mobile : "-";
          return (
            <Tooltip title={phoneToDisplay} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(phoneToDisplay)}</span>
            </Tooltip>
          );
        },
      },
    ];
    return columns;
  });

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    setIsLoading(true);
    try {
      getClinetsList({
        ...filters,
        type: "RETAIL_CLIENT",
      }).then((res) => {
        // console.log(".......................", res?.data?.data?.client);
        if (
          res?.data?.code === "LVLI0000" &&
          res?.data?.data &&
          res?.data?.data?.client
        ) {
          setRecords(res?.data?.data?.client);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("fail to load.", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      name: storeName,
      pageSize: filters.pageSize,
      pageNumber: e + 1,
      code: clientID,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
      name: storeName,
      code: clientID,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      getClinetsList({
        pageNumber: 1,
        pageSize: filters.pageSize,
        name: storeName,
        code: clientID,
        type: "RETAIL_CLIENT",
      }).then((res) => {
        if (
          res?.data?.code === "LVLI0000" &&
          res?.data?.data &&
          res?.data?.data?.client
        ) {
          setRecords(res?.data?.data?.client);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("fail to load." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setClinetID("");
    setStoreName("");
    setFilters({
      ...defaultFilters,
      pageNumber: 1,
      pageSize: 5,
      name: "",
      code: "",
    });
  };

  /**
   * @method handleAgreeButtonClick
   * 点击确定插入数据
   */
  const handleAgreeButtonClick = () => {
    if (selectedValue) {
      let filterResult = records.filter((item) => {
        if (item.id === selectedValue) {
          return true;
        } else {
          return false;
        }
      });

      if (filterResult && filterResult.length > 0) {
        addFormik.setFieldValue("email", filterResult[0]?.email);
        addFormik.setFieldValue("clientId", filterResult[0]?.id);
      }
    }
    setStoreName("");
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        <Grid
          display={"flex"}
          sx={{
            background: "#FFF",
            height: "100px",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              margin: "30px 30px",
            }}
          >
            <TextField
              size="small"
              label={t("LVLRC0037")}
              placeholder={t("LVLRC0038")}
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            ></TextField>
          </Box>
          <Box
            sx={{
              margin: "30px 30px",
            }}
          >
            <TextField
              size="small"
              label={t("LVLRC0025")}
              placeholder={t("LVLRC0026")}
              value={clientID}
              onChange={(e) => setClinetID(e.target.value)}
            ></TextField>
          </Box>
          <Box sx={{ margin: "35px 35px" }}>
            <Button
              variant="contained"
              sx={{ marginLeft: 1, p: 0.5 }}
              style={{
                height: "28px",
              }}
              onClick={handleSubmit}
            >
              {t("LVLGF0009")}
            </Button>

            <Button
              style={{
                height: "28px",
              }}
              sx={{ marginLeft: 1, p: 0.5 }}
              variant="outlined"
              onClick={handleReset}
            >
              {t("LVLGF0008")}
            </Button>
          </Box>
        </Grid>

        {isLoading ? (
          <RingLoader
            color={"#597ef7"}
            loading={isLoading}
            cssOverride={override}
            size={60}
            speedMultiplier={3}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <DataTable
            columns={columns}
            rows={records}
            onSelection={() => console.log()}
            page={filters.pageNumber - 1}
            totalRecords={totalRecords}
            rowsPerPage={filters.pageSize}
            onPageChange={(pn) => handlePageChange(pn)}
            onPageSizeChange={(ps) => handlePageSize(ps)}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined" size="large">
          {t("LVLDB0012")}
        </Button>
        <Button
          onClick={handleAgreeButtonClick}
          variant="contained"
          size="large"
        >
          {t("LVLDV0096")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCustomer;
