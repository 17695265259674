import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Grid, Button } from "@mui/material";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import FadeLoader from "react-spinners/FadeLoader";
import { ReactComponent as NoContent } from "@/assets/images/NoContent.svg";
const ShowProduct = forwardRef((props, ref) => {
  const {
    registerPicture,
    realTimePicture,
    captureTime,
    rectList,
    isLoading,
    setIsLoading,
  } = props;
  const [registerLightboxOpen, setRegisterLightboxOpen] = useState(false);
  const [realTimeLightboxOpen, setRealTimeLightboxOpen] = useState(false);
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const realTimePictureCanvasRef = useRef(null);
  const [dataURL, setDataURL] = useState(null);
  const [realTimePictureURl, setRealTimePictureURl] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: 330, height: 240 });

  const override = {
    display: "block",
    margin: "24% auto",
    borderColor: "rgba(145, 204, 117, 0.8)",
  };

  // 通用的加载图片到 Canvas 的函数
  const loadImageToCanvas = (canvas, imgSrc, canvasSize) => {
    setIsLoading(true);
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.setAttribute("crossOrigin", "Anonymous");
      img.onload = () => {
        canvas.width = canvasSize.width;
        canvas.height = canvasSize.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };

      img.src = imgSrc;
      setIsLoading(false);
    }
  };

  // 使用 useImperativeHandle 将方法暴露给父组件
  useImperativeHandle(ref, () => ({
    handlerCapiture: handlerCapiture,
  }));

  // 通用的异步加载图片函数
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "Anonymous");
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error("Failed to load image"));
      img.src = src;
    });
  };

  // 优化后的 handleCanvasActions 函数
  const handleCanvasActions = async (
    callback,
    pictureSrc,
    canvasRef,
    isRealTime,
    canvasSize,
    rectList,
    setUrlCallback
  ) => {
    let canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");

      try {
        // 加载图片
        const img = await loadImage(pictureSrc);
        let newWidth, newHeight;
        if (callback === boxOOpen || callback === openRealTime) {
          newWidth = canvasSize.width * 6; // 放大两倍
          newHeight = canvasSize.height * 6; // 放大两倍
        } else {
          newWidth = canvasSize.width;
          newHeight = canvasSize.height;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;

        // 计算比例以适应Canvas
        const scaleX = canvas.width / img.width;
        const scaleY = canvas.height / img.height;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        rectList.forEach(({ x1, y1, x2, y2, x3, y3, x4, y4 }) => {
          ctx.beginPath();

          // 确保绘制顺序正确，按顺时针或逆时针方向
          ctx.moveTo(x1 * scaleX, y1 * scaleY);
          ctx.lineTo(x2 * scaleX, y2 * scaleY);
          ctx.lineTo(x3 * scaleX, y3 * scaleY);
          ctx.lineTo(x4 * scaleX, y4 * scaleY);
          ctx.closePath(); // 确保路径闭合

          ctx.strokeStyle = "red";
          ctx.lineWidth =
            callback === boxOOpen || callback === openRealTime ? 6 : 1;
          ctx.stroke();
        });

        setUrlCallback(canvas.toDataURL("image/png"));
      } catch (error) {
        console.error("Error loading image:", error);
      }
    }
  };

  useEffect(() => {
    loadImageToCanvas(canvasRef.current, registerPicture, canvasSize);
  }, [registerPicture]);

  useEffect(() => {
    loadImageToCanvas(
      realTimePictureCanvasRef.current,
      realTimePicture,
      canvasSize
    );
  }, [realTimePicture]);

  const handlerCapiture = () => {
    loadImageToCanvas(
      realTimePictureCanvasRef.current,
      realTimePicture,
      canvasSize
    );
    loadImageToCanvas(canvasRef.current, registerPicture, canvasSize);
  };

  const boxOOpen = () => {
    setRegisterLightboxOpen(true);
    handleCanvasActions(
      boxOOpen,
      registerPicture,
      canvasRef,
      false,
      canvasSize,
      rectList,
      setDataURL
    );
  };

  const handleCloseLightbox = () => {
    setRegisterLightboxOpen(false);
    handleCanvasActions(
      handleCloseLightbox,
      registerPicture,
      canvasRef,
      false,
      canvasSize,
      rectList,
      setDataURL
    );
  };

  const openRealTime = () => {
    setRealTimeLightboxOpen(true);
    handleCanvasActions(
      openRealTime,
      realTimePicture,
      realTimePictureCanvasRef,
      true,
      canvasSize,
      rectList,
      setRealTimePictureURl
    );
  };

  const closeRealTime = () => {
    setRealTimeLightboxOpen(false);
    handleCanvasActions(
      closeRealTime,
      realTimePicture,
      realTimePictureCanvasRef,
      true,
      canvasSize,
      rectList,
      setRealTimePictureURl
    );
  };

  return (
    <Grid fontSize={24}>
      <Grid sx={{ background: "#FFF", borderRadius: "15px" }} p={3}>
        <Box fontWeight={700} marginLeft={2}>
          {t("PCS40")}
        </Box>

        <Grid display={"flex"}>
          <Grid marginLeft={2}>
            <Box whiteSpace={"nowrap"}>{t("PCS48")}</Box>
            <Box>
              {isLoading ? (
                <Box minWidth={330}>
                  <FadeLoader
                    color={"#597ef7"}
                    loading={isLoading}
                    cssOverride={override}
                    size={60}
                    speedMultiplier={3}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Box>
              ) : (
                <Box>
                  {registerPicture === undefined || realTimePicture === null ? (
                    <Box
                      minWidth={330}
                      height={240}
                      border={"1px solid #c0c0c0"}
                    >
                      <Box margin={"5% 25%"}>
                        <NoContent></NoContent>
                        <p
                          style={{
                            background: `linear-gradient(to right, #7F7FD5, #86A8E7,#91EAE4)`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            textAlign: "center",
                            fontWeight: 700,
                          }}
                        >
                          {t("LVL0028")}
                        </p>
                      </Box>
                    </Box>
                  ) : (
                    <Box minWidth={330} height={240}>
                      <canvas
                        id="myCanvas"
                        width={canvasSize.width}
                        height={canvasSize.height}
                        ref={canvasRef}
                        style={{ borderRadius: "15px" }}
                        onClick={boxOOpen}
                      ></canvas>

                      {registerLightboxOpen && (
                        <Lightbox
                          mainSrc={dataURL}
                          onCloseRequest={handleCloseLightbox}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>

          <Grid marginLeft={4}>
            <Grid display={"flex"} whiteSpace={"nowrap"}>
              <Box>{t("PCS49")}</Box>
              {captureTime !== "" ? (
                <Box fontSize={18} ml={1} mt={1}>
                  ({captureTime})
                </Box>
              ) : null}
            </Grid>

            {isLoading ? (
              <Box minWidth={330}>
                <FadeLoader
                  color={"#597ef7"}
                  loading={isLoading}
                  cssOverride={override}
                  size={60}
                  speedMultiplier={3}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Box>
            ) : (
              <Box>
                {registerPicture === undefined || realTimePicture === null ? (
                  <Box minWidth={330} height={240} border={"1px solid #c0c0c0"}>
                    <Box margin={"5% 25%"}>
                      <NoContent></NoContent>
                      <p
                        style={{
                          background: `linear-gradient(to right, #7F7FD5, #86A8E7,#91EAE4)`,
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",

                          textAlign: "center",
                          fontWeight: 700,
                        }}
                      >
                        {t("LVL0028")}
                      </p>
                    </Box>
                  </Box>
                ) : (
                  <Box minWidth={330} height={240}>
                    <canvas
                      id="myCanvas"
                      width={canvasSize.width}
                      height={canvasSize.height}
                      ref={realTimePictureCanvasRef}
                      style={{ borderRadius: "15px" }}
                      onClick={openRealTime}
                    ></canvas>
                    {realTimeLightboxOpen && (
                      <Lightbox
                        mainSrc={realTimePictureURl}
                        onCloseRequest={closeRealTime}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ShowProduct;
