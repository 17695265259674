import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import backgroundImage from "@/assets/AI_for_Retail.svg";
import Logo from "@/assets/logo/New Zata Logo Name.png";
import { ReactComponent as I18n } from "@/assets/images/icon_i18n.svg";
import { ReactComponent as ContactUs } from "@/assets/images/icon_contact_us.svg";
import Footer from "../Footer";
import AppLanguage from "@/lang/AppLanguages";

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
export default function OnboardLayout(props) {
  const bgStyle = {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(235,246,241)",
  };

  const switchLanguage = (code) => {
    AppLanguage.setLanguage(code);
    window.location.reload();
  };
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  const handlerSwitch = () => {
    let language = AppLanguage.getDefaultLanguage();
    const newLanguage = language === "en" ? "zh" : "en"; // 如果当前是英文，切换为中文；否则切换为英文
    switchLanguage(newLanguage);
    popupState.close();
  };
  return (
    <div style={bgStyle}>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexGrow: 1,
        }}>
        <Grid
          style={{
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "50vw",
            height: "90vh",
            top: "5%",
            left: "5%",
            position: "absolute",
            backgroundImage: `url(${backgroundImage})`,
          }}></Grid>
        <Grid>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignContent: "center",
              pr: 3,
              pt: 3,
            }}>
            <Box style={{ cursor: "pointer" }} pr={2}>
              <ContactUs height={32} width={32} />
            </Box>
            <Button style={{ cursor: "pointer" }} onClick={handlerSwitch}>
              <I18n height={32} width={32} />
            </Button>
          </Box>

          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100vw",
              minHeight: "70vh",
              justifyContent: "flex-end",
              alignContent: "center",
            }}>
            <Grid
              item
              elevation="0"
              lg={3}
              xs={10}
              style={{
                borderRadius: "0px 5px 5px 0px",
                boxShadow: "0px 0px 5px #76707066",
                minHeight: "30vh",
                marginRight: "150px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                  "& > :not(style)": {
                    mt: 4,
                    mb: 2,
                  },
                }}>
                {/* <Logo height={35} width={300} /> */}
                <img src={Logo} height={80} width={300}></img>
              </Box>
              <Box
                sx={{
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    pl: "10%",
                    pr: "10%",
                  },
                }}>
                {props.children}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        style={{
          minWidth: "100%",
          height: "50px",
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
        }}>
        <Box minWidth={"100%"}>
          <Footer />
        </Box>
      </Box>
    </div>
  );
}
