import React, { useState, useEffect } from "react";
import { Grid, Box, Card } from "@mui/material";
import RightViewLayout from "@/components/RighViewLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { createValidation } from "@/util/validationUtils";
import DeviceNumber from "./component/DeviceNumber";
import SubmitButton from "@/components/Retail-components/SubmitButton";
import AccountInfo from "./component/AccountInfo";
import ContractInfo from "./component/ContractInfo";
import {
  SaveSubscription,
  UpdateRecord,
  addExtend,
  addDeviceNum,
} from "@/services/subscription";
import { useSnackbar } from "notistack";
import { getInitialValues } from "./js/InitialValues";
function AddSubscription(props) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deviceConfig, setDeviceConfig] = useState([]); // 设备数量控制
  const [accountConfig, setAccountConfig] = useState([]); //新增零售商
  const [contractConfig, setContractConfig] = useState([]); // 合用信息模块配置
  const [isLoading, setIsLoading] = useState(false);
  const formConfig = [
    ...deviceConfig,
    ...(state?.type === "add" ? accountConfig : []),
    ...contractConfig,
  ];

  let initialValues = getInitialValues(state);

  const addFormik = useFormik({
    initialValues: initialValues,
    validationSchema: createValidation(formConfig),
    onSubmit: (values) => {
      if (state?.type === "update") {
        setIsLoading(true);
        UpdateRecord(values).then((res) => {
          if (res?.data?.code?.startsWith("LVLE")) {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
            return;
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "success",
            });
            setIsLoading(false);
            navigate("/SubRecord");
          }
        });
      } else if (state?.type === "more") {
        setIsLoading(true);
        addDeviceNum(values).then((res) => {
          if (res?.data?.code?.startsWith("LVLE")) {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
            return;
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "success",
            });
            setIsLoading(false);
            navigate("/SubRecord");
          }
        });
      } else if (state?.type === "extend") {
        setIsLoading(true);
        addExtend(values).then((res) => {
          if (res?.data?.code?.startsWith("LVLE")) {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
            return;
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "success",
            });
            setIsLoading(false);
            navigate("/SubRecord");
          }
        });
      } else {
        setIsLoading(true);

        SaveSubscription(values).then((res) => {
          if (res?.data?.code?.startsWith("LVLE")) {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
            return;
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "success",
            });
            setIsLoading(false);
            navigate("/SubRecord");
          }
        });
      }
    },
  });

  return (
    <RightViewLayout
      navigateBack={state?.type === "add" ? "/Subscription" : "/SubRecord"}
      title={t("subscription.subscription")}
    >
      <Grid>
        <form noValidate onSubmit={addFormik.handleSubmit}>
          <Card>
            <Grid p={4}>
              <DeviceNumber
                addFormik={addFormik}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              ></DeviceNumber>
            </Grid>

            <Grid p={4} mt={-6}>
              <AccountInfo
                addFormik={addFormik}
                accountConfig={accountConfig}
                setAccountConfig={setAccountConfig}
              ></AccountInfo>
            </Grid>

            {state?.type === "add" || state?.type === "update" ? (
              <Grid p={4} mt={-6}>
                <ContractInfo
                  addFormik={addFormik}
                  contractConfig={contractConfig}
                  setContractConfig={setContractConfig}
                ></ContractInfo>
              </Grid>
            ) : null}
          </Card>
          <SubmitButton
            type={"submit"}
            isLoading={isLoading}
            backRoute={state?.type === "add" ? "/Subscription" : "/SubRecord"}
          ></SubmitButton>
        </form>
      </Grid>
    </RightViewLayout>
  );
}

export default AddSubscription;
