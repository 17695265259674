const en = {
  LVL0001: "This field is required",
  LVL0002: "Only accepts alphabets, numerics and hyphen",
  LVL0003: "Invalid Email address",
  LVL0004: "Only accepts alphabets, numerics",
  LVL0005: "Only accepts numerics",
  LVL0006: "Only accepts alphabets, numerics and space",
  LVL0007: "Country code is required",
  LVL0008:
    "Mobile number including country code must be between 8 to 23 digits",
  LVL0009: "Invalid password",
  LVL0010: "Wrong password",
  LVL0011: "English",
  LVL0012: "Shelf Monitoring Algorithm",
  LVL0013: "Save",
  LVL0014: "Auto Detect SKU in Left Side Register Photo",
  LVL0015: "Indonesian",
  LVL0016: "Japanese",
  LVL0017: "Chinese",
  LVL0018: "Mobile Number",
  LVL0019: "Password",
  LVL0020: "Create Your Password",
  LVL0021: "Confirm Your Password",
  LVL0022: "Company Profile",
  LVL0023: "Edit User's Profile",
  LVL0024: "View Company",
  LVL0025: " View Profile",
  LVL0026: "Bound to Account",
  LVL0027: "Pricing Information",
  LVL0028: "No content",
  LVL0029: "Change failed",
  LVL0030: "Product pricing information",
  LVL0031: "Only numbers can be entered and must be integers.",
  LVL0032: "Spanish",
  LVL0033: "German",
  LVL0034: "Switch Company",

  LVLDB0001: "Outlets Dashboard",
  LVLDB0002: "Location",
  LVLDB0003: "Retail Client",
  LVLDB0004: "Outlet",
  LVLDB0005: "Device",
  LVLDB0006: "Data Access Control",
  LVLDB0007: "Workshop",
  LVLDB0008: "Language",
  LVLDB0009: "No of Records per page",
  LVLDB0010: "No Record Found.",
  LVLDB0011: "OK",
  LVLDB0012: "Cancel",
  LVLDB0013: "Principle",
  LVLDB0014: "Re-Capture",
  LVLDB0015: "Re-Result",
  LVLDB0016: "Re-Reset",
  LVLDB0017: "Preview",
  LVLDB0018: "Edit Principle",
  LVLDB0019: "Name",
  LVLDB0020: "View",
  LVLDB0021: "Edit",
  LVLDB0022: "Delete",
  LVLDB0023: "ResendEmail",
  LVLDB0024: "Add New Principle",
  LVLDB0025: "Address Line",
  LVLDB0026: "Delete",
  LVLDB0027: "View Principle",
  LVLDB0028: "Send",
  LVLDB0029: "Back to Login",
  LVLDB0030: "Login",
  LVLDB0031: "Register",
  LVLDB0032: "Edit Device",
  LVLDB0033: "Code",
  LVLDB0034: "Logout",
  LVLDB0035: "Loading...",
  LVLDB0036: "Authoritation",
  LVLDB0037: "View Authoritation",
  LVLDB0038: "Edit Authoritation",
  LVLDB0039: "Reset Passwords",
  LVLDB0040: "View Image",

  LVLGF0001: "Outlets Country",
  LVLGF0002: "State/Province",
  LVLGF0003: "City",
  LVLGF0004: "City Zone",
  LVLGF0005: "Retail Client",
  LVLGF0006: "Outlet",
  LVLGF0007: "Outlet Type",
  LVLGF0008: "Reset",
  LVLGF0009: "Find",
  LVLGF0010: "Clients Country",
  LVLGF0011: "No Options",
  LVLGF0012: "Choose A Country",
  LVLGF0013: "Outlets",
  LVLGF0014: "Enter Authorization Level Name",

  LVLDAC0001: "Data Access Control",
  LVLDAC0002: "Device",
  LVLDAC0003: "Retail Client",
  LVLDAC0004: "Principle",
  LVLDAC0005: "Choose One Software to Assign Data access",
  LVLDAC0006: "Cancel",
  LVLDAC0007: "Allow Access",
  LVLDAC0008: "Device Number",
  LVLDAC0009: "Alias Name",
  LVLDAC0010: "Device Model",
  LVLDAC0011: "Device Type",
  LVLDAC0012: "Outlets",
  LVLDAC0013: "Status",
  LVLDAC0014: "Actions",

  LVLDAC0018: "Organization Name",
  LVLDAC0019: "Organization Code",
  LVLDAC0020: "Email",
  LVLDAC0021: "Mobile",
  LVLDAC0022: "Address",
  LVLDAC0023: "Country",
  LVLDAC0024: "State",
  LVLDAC0025: "City",
  LVLDAC0026: "Password is mismatched",
  LVLDAC0027: "Set Password",
  LVLDAC0028: "New Password",
  LVLDAC0029: "Are you sure want to delete selected Record?",
  LVLDAC0030: "Confirm Password",
  LVLDAC0031: "Principal Company Name",
  LVLDAC0032: "My Profile",
  LVLDAC0033: "User's Profile",
  LVLDAC0034: "Forgot Password",
  LVLDAC0035: "Create Account",
  LVLDAC0038: "Company Name",
  LVLDAC0036: "Secret Key",
  LVLDAC0037: "App Id",
  LVLDAC0039: "Company Code",

  LVLWS0001: "Workshop",
  LVLWS0002: "Contents Manage",
  LVLWS0003: "Retail AI Analysis",
  LVLWS0004: "E Price Tag",
  LVLWS0005: "Refresh Success !",

  LVLOT0001: "Outlet List",
  LVLOT0002: "Client",
  LVLOT0003: "Outlet Name",
  LVLOT0004: "Outlet Type",
  LVLOT0005: "Country",
  LVLOT0006: "Province/State",
  LVLOT0007: "City",
  LVLOT0008: "City Zone",
  LVLOT0009: "Time Zone",
  LVLOT0010: "Actions",
  LVLOT0011: "Add Outlet",
  LVLOT0012: "Address",
  LVLOT0013: "Latitude",
  LVLOT0014: "Longitude",
  LVLOT0015: "Back",
  LVLOT0016: "Submit",
  LVLOT0017: "Edit Outlet",
  LVLOT0018: "View Outlet",
  LVLOT0019: "Outlet is linked to device",
  LVLOT0020: "Outlet id Successfully Deleted",
  LVLOT0021: "Outlet location in this country",
  LVLOT0022: "Outlet",

  LVLRC0001: "Retail Clients",
  LVLRC0002: "Organization Name",
  LVLRC0003: "Organization Code",
  LVLRC0004: "Email",
  LVLRC0005: "Mobile",
  LVLRC0006: "Address",
  LVLRC0007: "Country",
  LVLRC0008: "State/Province",
  LVLRC0009: "City",
  LVLRC0010: "Actions",
  LVLRC0011: "Search by Name",

  LVLRC0012: "Add Retail Client",
  LVLRC0013: "Owner First name",
  LVLRC0014: "Owner Last name",
  LVLRC0015: "Owner Email",
  LVLRC0016: "Submit",
  LVLRC0017: "Back",
  LVLRC0018: "Edit Retail Client",
  LVLRC0019: "View Retail Client",
  LVLRC0020: "Country Code",
  LVLRC0021: "Are you sure want to delete selected Record?",
  LVLRC0022: "Retail Client",

  LVLRC0023: "Name",
  LVLRC0024: "Please enter the Name...",
  LVLRC0025: "Code",
  LVLRC0026: "Please enter the Code...",
  LVLRC0027: "Address Line",
  LVLRC0028: "View",
  LVLRC0029: "Edit",
  LVLRC0030: "Person List",
  LVLRC0031: "Retail Client Management",
  LVLRC0032: "Company Profile",
  LVLRC0033: "Name",
  LVLRC0034: "Code",
  LVLRC0035: "Email",
  LVLRC0036: "Phone",
  LVLRC0037: "Name",
  LVLRC0038: "Please enter retailer name...",
  LVLRC0039: "Device SN",
  LVLRC0040: "Device Status",
  LVLRC0041: "Device Name",
  LVLRC0042: "Outlet Name",
  LVLRC0043: "Enter Outlet Name.",
  LVLRC0044: "Enter Device Name.",
  LVLRC0045: "Enter Device SN.",
  LVLRC0046: "Enter Device Status.",
  LVLRC0047: "Device Number",
  LVLRC0048: "Expiration Date",
  LVLRC0049: "Subscription Create Date",

  LVLRCP001: "Person List",
  LVLRCP002: "Person Name",
  LVLRCP003: "Person ID",
  LVLRCP004: "Email",
  LVLRCP005: "Mobile",
  LVLRCP006: "Date Of Joining",
  LVLRCP007: "First name cannot exceed 100 characters",
  LVLRCP008: "Last name cannot exceed 25 characters",
  LVLRCP009: "PersonID cannot exceed 20 characters",
  LVLRCP010: "Mobile Number is required",
  LVLRCP011: "Create Person",
  LVLRCP012: "Allowed only *JPEG, *JPG, *PNG",
  LVLRCP013: "'Maximum size of 3 MB",
  LVLRCP014: "First Name",
  LVLRCP015: "Last Name",
  LVLRCP016: "DD-MM-YYYY (Please select Date)",
  LVLRCP017: "Edit Person",
  LVLRCP018: "View Person",
  LVLRCP019: "Joining date cannot be a future date",
  LVLRCP020: "Invailid Join Date!",
  LVLRCP021: "Organization Attribute",
  LVLRCP022: "Click here to upload picture",
  LVLRCP023: "Password",
  LVLRCP024: "Confirm Password",
  LVLRCP025: "Please enter your PIN.",
  LVLRCP026: "Please confirm your password.",
  LVLRCP027: "Old Password",
  LVLRCP028: "New Password",
  LVLRCP029: "Person Avatar",

  LVLLOC0001: "Country Name",
  LVLLOC0002: "Country Code",
  LVLLOC0003: "Actions",
  LVLLOC0004: "Are you sure want to delete selected Record?",
  LVLLOC0005: "Country having both states and outlet cannot be deleted",
  LVLLOC0006: "Country having states cannot be deleted",
  LVLLOC0007: "Location",

  LVLLOC0008: "State Name",
  LVLLOC0009: "State Code",
  LVLLOC0010: "Country",
  LVLLOC0011: "Actions",
  LVLLOC0012: "Are you sure want to delete selected Record?",
  LVLLOC0013: "State having both city and outlet cannot be deleted",
  LVLLOC0014: "State having city cannot be deleted",

  LVLLOC0015: "City Name",
  LVLLOC0016: "City Code",
  LVLLOC0017: "State",
  LVLLOC0018: "Actions",
  LVLLOC0019: "Are you sure want to delete selected Record?",
  LVLLOC0020: "City having outlets cannot be deleted",

  LVLLOC0021: "Outlet Type Name",
  LVLLOC0022: "Outlet Type Code",
  LVLLOC0023: "Actions",
  LVLLOC0024: "Are you sure want to delete selected Record?",
  LVLLOC0025: "OutletType deleted sucessfully",

  LVLLOC0026: "City Zone Name",
  LVLLOC0027: "City Zone Code",
  LVLLOC0028: "Actions",
  LVLLOC0029: "Are you sure want to delete selected Record?",

  LVLLOC0030: "OutletType is linked to outlet",
  LVLLOC0031: "CityZone is linked to outlet",
  LVLLOC0032: "CityZone deleted SuccessFully",

  LVLLOC0033: "Add Country",
  LVLLOC0034: "Name",
  LVLLOC0035: "Code",
  LVLLOC0036: "Submit",
  LVLLOC0037: " Back",

  LVLLOC0038: " Edit Country",

  LVLLOC0039: " Add State",
  LVLLOC0040: " Country",

  LVLLOC0041: " Edit State",

  LVLLOC0042: " Add City",
  LVLLOC0043: " State",

  LVLLOC0044: " Edit City",

  LVLLOC0045: " Add City Zone",

  LVLLOC0046: " Edit City Zone",

  LVLLOC0047: " Add OutletType",

  LVLLOC0048: " Edit OutletType",

  LVLLOC0049: "Country",
  LVLLOC0050: "State",
  LVLLOC0051: "City",
  LVLLOC0052: "City zone",
  LVLLOC0053: "Outlet Type",
  LVLLOC0054: "View Country",
  LVLLOC0055: "CreateAt",
  LVLLOC0056: "View state",
  LVLLOC0057: "View City",
  LVLLOC0058: "View City Zone",
  LVLLOC0059: "View Outlet Type",

  LVLDV0001: "Device Number",
  LVLDV0002: "Alias Name",
  LVLDV0003: "Device Model",
  LVLDV0004: "Device Type",
  LVLDV0005: "Outlets",
  LVLDV0006: "Status",
  LVLDV0007: "Actions",
  LVLDV0008: "Device List",

  LVLDV0009: "Please read the instructions and check the box to Add a Device.",
  LVLDV0010: "Add Device to Outlet",
  LVLDV0011: "Device Network Configuration Steps",
  LVLDV0012: "Step 1: Power up and turn on the Device",
  LVLDV0013:
    "If the Device has a network, it will automatically connect to the network and start working.",
  LVLDV0014: "Step 2: Configure the Network",
  LVLDV0015:
    "You may use Bluetooth to set up the network. Or some Device has touch screen that has network setting in Firmware.",
  LVLDV0016: "Step 3: Restore the Factory Settings",
  LVLDV0017:
    "Some Devices can not have network setting. You may try to Reset the Device factory setting.",
  LVLDV0018: "I have read these instructions",
  LVLDV0019: "Continue",

  LVLDV0020: "This field is required",
  LVLDV0021: "Device bound successfully",
  LVLDV0022: "Please select your Zone",
  LVLDV0023: "Add Device to Outlet",
  LVLDV0024: "Manual Register Device",
  LVLDV0025: "Power Up and Set Device Network",
  LVLDV0026:
    "1. Plug in the network cable if Device support Ethernet function.",
  LVLDV0027:
    "2. Enter your Device Ethernet setting/WiFi setting menu to enter communication setting page. Network setup is successful, Device will display a QR code in standby page.",
  LVLDV0028:
    "3. On the side of Device box or on the back of Device, can find the Device Serial Number.",
  LVLDV0029: "4. Fill in Device Serial Number on system.",
  LVLDV0030: "Please Enter Device Serial Number",
  LVLDV0031: "Bind Device to Outlet",
  LVLDV0032: "Bind Zone",
  LVLDV0033: "Outlet",
  LVLDV0034: "Bind Zone",
  LVLDV0035: "Device Serial Number",
  LVLDV0036: "Add",
  LVLDV0037: "Please specify the device to a Retail client and Outlet.",
  LVLDV0038: " This device will sync the same time zone of the outlet.",
  LVLDV0039: "Device details",
  LVLDV0040: "Unbind device",
  LVLDV0041: "Reboot",
  LVLDV0042: "Firmware Upgrade",
  LVLDV0043: "Client",
  LVLDV0044: "Outlet Time Zone",
  LVLDV0045: "Bind",
  LVLDV0046: "Device bound successfully",
  LVLDV0047: "View Device Details",
  LVLDV0048: "Log Time",
  LVLDV0049: "Log Type",
  LVLDV0050: "Log Code",
  LVLDV0051: "Enable",
  LVLDV0052: "Device Log",
  LVLDV0053: "Device Name",
  LVLDV0054: "Serial Number",
  LVLDV0055: "Outlet Id",
  LVLDV0056: "Client Name",
  LVLDV0057: "Device Unbind successfully",
  LVLDV0058: "Are you sure to unbind selected device?",
  LVLDV0059: "Device",
  LVLDV0060: "Auto Capture:",
  LVLDV0061: "Position",
  LVLDV0062: "Recognition Time",
  LVLDV0063: "Product Name",

  LVLDV0064: "Barcode",
  LVLDV0065: "Category",
  LVLDV0066: "RegPhoto",
  LVLDV0067: "Device Setting",
  LVLDV0068: "Device ID",
  LVLDV0069: "Drag to Set Crossing Line and Direction",
  LVLDV0070: "PRODUCT NAME",
  LVLDV0071: "BARCODE",
  LVLDV0072: "CATEGORE LEVEL1",
  LVLDV0073: "CATEGORE LEVEL2",
  LVLDV0074: "Product Bar Code",
  LVLDV0075: "Category Level1",
  LVLDV0076: "Category Level2",
  LVLDV0077: "Sn",
  LVLDV0078: "Please enter the Sn...",
  LVLDV0079: "Bound to Account",
  LVLDV0080: "Device ID",
  LVLDV0081: "Edit Device Details",
  LVLDV0082: "Device ID",
  LVLDV0083: "Serial Number",
  LVLDV0084: "Device Alias Name",
  LVLDV0085: "Device Model",
  LVLDV0086: "Device Type",
  LVLDV0087: "Client Name",
  LVLDV0088: "Outlet",
  LVLDV0089: "Outlet ID",
  LVLDV0090: "Status",
  LVLDV0091: "Online",
  LVLDV0092: "Offline",
  LVLDV0093: "Minutes",
  LVLDV0094: "Device serial number or Signage ID",
  LVLDV0095: "Enter device serial number or the Signage",
  LVLDV0096: "Confirm",
  LVLDV0097: "Bind Devices to One Principal or One Retail Outlets",
  LVLDV0098: "Please specify the device to a site and zone.",
  LVLDV0099: "This device will sync the same time zone of the site.",
  LVLDV0100: "This Device will Sync the same Time Zone",
  LVLDV0101: "of the Site",
  LVLDV0102: "This field is required",
  LVLDV0103: "Set Success !",
  LVLDV0104: "Data loaded successfully.",
  LVLDV0105: "The device has no available channels.",
  LVLDV0106: "Unhandled response code",
  LVLDV0107: "Please select a retailer.....",
  LVLDV0108: "Channel Setting",
  LVLDV0109: "Main OrSub",

  RTPD0001: "Retail Product",
  RTPD0002: "Add New Product",
  RTPD0003: "Edit New Product",
  RTPD0004: "Product Name",
  RTPD0005: "Product Id",
  RTPD0006: "Bar Code",
  RTPD0007: "Open API Management",
  RTPD0008: "Open API Add",
  RTPD0009: "Open API Updated",
  RTPD00010: "Acquire success !",
  RTPD00011: "Acquire fail !",
  RTPD00013: "In Acquire.......",
  RTPD00014: "Product Picture",

  RTPD00012: "Location",

  RTMT0001: "Metadata",
  RTMT0002: "Channel ID/Name",
  RTMT0003: "Algorithm",
  RTMT0004: "Event Time",
  RTMT0005: "Register Photo",
  RTMT0006: "Event Photo",
  RTMT0007: "Recognition Target",
  RTMT0008: "Event Metadata",
  RTMT0009: "Shelf Monitoring Algorithm",
  RTMT0010: "Metadata List",
  RTMT0011: "Traffic/crowd analysis events",
  RTMT0012: "Shelf analysis event",
  RTMT0013: "Whether it is a sub-device",
  RTMT0014: "Client Type",
  RTMT0015: "Age",
  RTMT0016: "Gender",
  RTMT0017: "Glasses",
  RTMT0018: "Hat",
  RTMT0019: "MaskStatus",
  RTMT0020: "trackId",
  RTMT0021: "ChannelNum",
  RTMT0022: "LowerColor",
  RTMT0023: "RideBike",
  RTMT0024: "Umbrella",
  RTMT0025: "UpperColor",
  RTMT0026: "Height",
  RTMT0027: "EventType",
  RTMT0028: "Bag",
  RTMT0029: "TypeName",
  RTMT0030: "Product Name",
  RTMT0031: "Please select the ChannelNum..",
  RTMT0032: "Sence",
  RTMT0033: "Primary Device",
  RTMT0034: "Subset Device",
  RTMT0035: "Other Device",
  PLACE01: "Please enter the Name...",
  PLACE02: "Whether to export pictures ?",
  PLACE03: "Organisation profile pic",
  PLACE04: "Email address already exist in the same or other company.",
  PLACE05: "email already exist",
  PLACE06: "New success!",
  PLACE07: "Being Saved.......",
  PLACE08: "Modify Successfully",
  PLACE09: "Delete Confirmation！",
  PLACE010: "Are you sure,you want to delete this retail product permanently?",
  PLACE011:
    "System will send a link to reset your password to the email used in youraccount, please check it carefully.",
  PLACE012: "Please Enter your Email.",
  PLACE013: "Don`t have an account?",
  PLACE014:
    "This account is the admin account for your organization, can create your partner or operator in application.",
  PLACE015: "Register with mobile",
  PLACE016: "Register with Email",
  PLACE017: "Please enter the Code...",
  PLACE018: "Record Deleted successfully.",
  PLACE019: "Record created successfully.",
  PLACE020: "Record Updated successfully.",
  PLACE021: "Outlet created successfully.",
  PLACE022: "Outlet Updated successfully.",
  PLACE023: "Outlet Deleted successfully.",
  PLACE024:
    "Note: Sub-device is the camera which can work independently, no need to use algorithm box.",

  LOCATION10: "OutLet Code",
  LOCATION11: "CityZone Name",
  LOCATION12: "CityZone Code",
  LOCATION13: "Synch",

  MENU01: "AI Dashboard",
  MENU02: "People Counting Summary",
  MENU03: "Shelf Monitoring Summary",
  MENU04: "Demographic Summary",
  MENU05: "People Counting",
  MENU06: "Shelf Monitoring",
  MENU07: "Demographic",
  MENU08: "Retail Client Management",
  MENU09: "Retail Outlet Management",
  MENU10: "Device Management",
  MENU11: "Retail Product",
  MENU12: "Metadata",
  MENU13: "Principle Management",
  MENU14: "Authoritation Management",
  MENU15: "Shelf Monitoring Summary Dashboard",
  MENU16: "Demographic Profiling Dashboard",

  MATADATA01: "Export",
  MATADATA02: "Yes",
  MATADATA03: "No",
  MATADATA04: "Check",
  MATADATA05: "Please select a date.",
  MATADATA06: "START DATE",
  MATADATA07: "END DATE",

  DSPC001: "Customer Statistics Algorithm",
  DSPC002: "Area Type",
  DSPC003: "line",
  DSPC004: "polygon",

  DSKU001: "Edit Detect SKU",
  DSKU002: "Position",
  DSKU003: "Recognition Time",
  DSKU004: "Product Name",
  DSKU005: "Barcode",
  DSKU006: "Category",
  DSKU007: "Registered Photo",

  PCS01: "People Counting Dashboard",
  PCS02: "Welcome back",
  PCS03: "PERIOD",
  PCS04: "COMPARE WITH",
  PCS05: "PREVIOUS PERIOD",
  PCS06: "START DATE",
  PCS07: "END DATE",
  PCS08: "STORE",
  PCS09: "ALL STORES",
  PCS10: "APPLY <br /> FILTERS",
  PCS11: "Total<br />Visitors",
  PCS12: "Entry Flow Trend",
  PCS13: "Overall Change",
  PCS14: "Highest Entry",
  PCS15: "Entry Volume",
  PCS16: "Previous period",
  PCS17: "Growth rate",
  PCS18: "Entry Rate",
  PCS19: "Conversion Rate",
  PCS20: "Prev.Period",
  PCS21: "Period",
  PCS22: "Conversion Rate",
  PCS23: "Prev.Period",
  PCS24: "Change",
  PCS25: "Please select a time period.",
  PCS26: "Week",
  PCS27: "month",
  PCS28: "Please select a Store Name.",
  PCS29: "Store Name",
  PCS30: "All Store",
  PCS31: "VISITORS",
  PCS32: "Day",
  PCS33: "Additional Information",
  PCS34: "Number of Product Facing",
  PCS35: "Out of Stock",
  PCS36: "Today's date is not optional.",
  PCS37: "PERIOD",
  PCS38: "APPLY FILTERS",
  PCS39: "Total<br />Captures",
  PCS40: "Shelf Monitoring",
  PCS41: "Brand",
  PCS42: "Match Frequency",
  PCS43: "Total",
  PCS44: "Discrepant<br /> Frequency<br/> Misplaced",
  PCS45: "Out Of Stock",
  PCS46: "Accuracy",
  PCS48: "BenchMark",
  PCS49: "Latest Capture",
  PCS50: "Line Placement",
  PCS51: "Overall Accuracy",
  PCS52: "Highest",
  PCS53: "Brand Accuracy",
  PCS54: "Lowest",
  PCS55: "Gender Distribution",
  PCS56: "Age Distribution",
  PCS57: "Visitor Demographic Distribution",
  PCS58: "Highest Visitors by Time",
  PCS59: "VISITORS",
  PCS60: "Least Visitors by Time",
  PCS61: "Highest Visitors by Demograph",
  PCS62: "Male",
  PCS63: "Least Visitors by Demograph",
  PCS64: "Total<br />VISITORS",
  PCS65: "Time",
  PCS66: "2 Hours",
  PCS67: "4 Hours",
  PCS68: "6 Hours",
  PCS69: "Age",
  PCS70: "Female",
  PCS71: "InSide",
  PCS72: "OutSide",
  PCS73: "Price Strategy",
  PCS74: "Authorization Level",
  PCS75: "Enter Authorization Level Name",
  PCS76: "Hide SecretKey",
  PCS77: "Show SecretKey",
  PCS78: "Please select a permission role.",
  PCS79: "Please select a  character.",
  PCS80: "Please select store.",
  PCS81: "Copy Success.",
  PCS82: "In Charge For Outlet",
  PCS83: "Whether to delete the current role?",
  PCS84: "successfully added.",
  PCS85: "Role name cannot be empty.",
  PCS86: "successfully edited.",
  PCS87: "Unbound store.",
  PCS88: "Please enter the product name...",
  PCS89: "Please click Reset identification area !",
  PCS90: "Please enter the device SN.",
  PCS91: "Please select the export time !",
  PCS92: "Loading... , Please Wait...",
  PCS93: "Please select character.",
  PCS94: "Please enter your PIN.",
  PCS95: "Please Select the Client...",
  PCS96: "Upload Photo",
  PCS97: "The device does not have a channel.",
  PCS98: "Get product pictures.",
  PCS99: "Identify product pictures.",
  PCS100: "Reset identification area.",
  PCS101: "Preview product picture.",
  PCS102: "Rows per page:",
  PCS103: "Be Ongoing......",
  PCS104: "Save success",
  PCS105: "No rows",
  PCS106: "rows selected",
  PCS107: "row selected",
  PCS108: "I agree and read",
  PCS109: "《Data Processing Protocol》",
  PCS110: "《Privacy agreement》",

  data: {
    private_agree: `Personal Information Protection and Privacy Policy`,
    private_agree_text: `<p><span style="font-size: 14px;"> This policy applies only toScreen DirectproductandServe.</span></p>
    <p><span style="font-size: 14px;"> New Date:2024Year10moon.</span></p>
    <p><span style="font-size: 14px;"> If you have any questions, comments or suggestions, please contact us through the following
            contact information:</span></p>
    <p><span style="font-size: 14px;"> e-mail:Support@zkdigimax.com</span></p>
    <p style="margin-top: 30px;"><span style="font-size: 14px;"> This policy will help you understand the following:</span></p>

    <p style="margin-top: 30px;"><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">Rules for the collection and use of personal information</span></p>
    <p><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">How we protect your personal information</span></p>
    <p><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">Your rights</span></p>
    <p><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">How do we handle personal information of minors?</span></p>
    <p><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">How this Policy is Updated</span></p>
    <p><span style="display: inline-block;width: 10px;height: 10px; background-color: black; 
        margin-right: 15px; "></span><span style="font-size: 14px;">How to Contact Us</span></p>
    <p style="text-indent: 2em"><span style="font-size: 14px;">Xiamen Enki Technology Co., Ltd.and its affiliates(hereinafter referred to as "Entropy Technology", "the Company" or "we") is fully aware of the importance of personal information to you and will do our best to protect your personal information safely and reliably. We are committed to maintaining your trust in us and abiding by the following principles to protect your personal information: the principle of consistency of rights and responsibilities, the principle of clear purpose, the principle of choice and consent, the principle of minimum necessity, the principle of ensuring security, the principle of subject participation, the principle of openness and transparency, etc. At the same time, Entropy Technology promises that we will take corresponding security protection measures to protect your personal information in accordance with the industry's mature security standards.</span></p>
    <p style="text-indent: 2em"><span style="font-size: 14px;">Before using this product (or service), please be sure to read and thoroughly understand this policy, and use the relevant products or services after confirming that you fully understand and agree to it. Once you start using this product or service, it means that you have fully understood and agreed to this policy.</span></p>
    <p style="margin-top: 35px;"><strong><span style="font-size: 14px;">1. Rules for the collection and use of personal information</span></strong></p>
    <p style="margin-top: 20px;"><strong><span style="font-size: 14px;">(I) What personal information do we collect?</span></strong></p>
    <p style="margin-top: 20px; text-indent: 2em"><span style="font-size: 14px;">1. Your useScreen Direct(Software Product Name), we will collect the following information from you:</span></p>


    <p style="text-indent: 2em"><span style="font-size: 14px;">The business functions we provide rely on some information to operate. If you choose to use this business function, you need to provide us with or allow us to collect the necessary information including:Phone number, position, name, email address, profile picture,Operation, Login Geolocation, Operation LoginIP, operation, login device type.</span></p>
    <p style="text-indent: 2em"><span style="font-size: 14px;">Depending on the software product you choose, you can enterPhone number/Name,existAccount login requires using email as accountYou must provide realMailOnly then can you use the related services.</span></p>
    <p style="margin-top: 20px; text-indent: 2em"><span style="font-size: 14px;">2. You may choose to provide us with or allow us to collect the following information: Personalavatar,Position,Phone number.</span></p>
    <p style="text-indent: 2em;"><span style="font-size: 14px;">This information is not necessary for the operation of the business function, but it is of great significance to improving service quality, developing new products or services, etc. We will not force you to provide this information, and your refusal will not have an adverse impact on the use of this business function.</span></p>
    <p style="text-indent: 2em;"><span style="font-size: 14px;">3. When you use this business function, our software will apply to you for the following system permissions related to personal information:Get location.</span></p>
    <p style="text-indent: 2em;"><span style="font-size: 14px;">If you do not authorize, we will not be able to provide the business function. In addition to the above permissions, you can choose whether to grant other system permissions to the software.</span></p>
    <p style="text-indent: 2em;"><span style="font-size: 14px;">When you contact us, we may save your communication/call records and content or information such as the contact information you leave in order to contact you or help you solve the problem, or record the solution and results of the relevant problem.</span></p>

     <p><strong><span style="font-size: 14px;">(II) How we use your personal information</span></strong></p>
 <p style="text-indent: 2em;"><span style="font-size: 14px;">1. We will use the necessary personal information to provide this business function. We will also use the following information to maintain and improve this business function, develop new business functions, etc.</span></p>
    <table style="width: auto; border-collapse: collapse;  margin: 0 auto;">
        <tbody>
            <tr>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Information Type
                </td>

                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Purpose of Use
                </td>

                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Shelf life
                </td>
            </tr>

            <tr>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Phone number, position, name, email address, profile picture
                </td>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Used to record account information
                </td>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Permanent (account cancellationHours）
                </td>
            </tr>

            <tr>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Operation, Login Geolocation, Operation LoginIP, operation, login device type
                </td>
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    Used to audit operation logs and login logs
                <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center;">
                    6Month(Regular deletion)
                </td>
            </tr>
        </tbody>
    </table>

     <p style="text-indent: 2em; margin-top: 20px;"><span style="font-size: 14px;">2. We will use non-essential personal information for the following purposes:</span></p>
            <table style="width: auto; border-collapse: collapse;  margin: 0 auto;">
                <tbody>
                    <tr>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Information Type
                        </td>
        
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Purpose of Use
                        </td>
        
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Shelf life
                        </td>
                    </tr>
        
                    <tr>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Store location, store name, store information, store manager contact information, store manager name
                        </td>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Responsible for managing the relationship between stores and equipment
                        </td>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Permanent (account cancellation occurs within hours)
                        </td>
                    </tr>
        
                    <tr>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            equipmentIPInformation, basic parameters of the device (device model, device time zone,Device color temperature, version number, volume, brightness, device screenshots,CPU/Memory information)
                        </td>
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Easy to manage equipment
                        <td colSpan="1" rowSpan="1" width="225" style="border: 1px solid black; padding: 8px; text-align: left; text-align: center; font-size: 14px;">
                            Permanent (device damage will be cancelled within hours)
                        </td>
                    </tr>
                </tbody>
            </table>
               <p style="margin-top: 35px">
      <strong
        ><span style="font-size: 14px"
          >(III) How we use cookies and similar technologies</span
        ></strong
      >
    </p>

    <p>
      <span style="font-size: 14px">1. Cookies</span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 14px"
        >Cookies and similar technologies are commonly used on the Internet. To
        ensure the normal operation of the website, we will store small data
        files called cookies on your computer or mobile device. Cookies usually
        contain identifiers, site names, and some numbers and characters. With
        the help of cookies, websites can store data such as your preferences.
        We will not use cookies for any purpose other than those described in
        this policy. You can manage or delete cookies according to your
        preferences. You can clear all cookies saved on your computer, and most
        web browsers have the function of blocking cookies. But if you do this,
        you need to change the user settings yourself every time you visit our
        website.</span
      >
    </p>
    <p>
      <span style="font-size: 14px">2. Other similar technologies</span>
    </p>

    <p style="text-indent: 2em">
      <span style="font-size: 14px"
        >In addition to Cookies, we will also use other similar technologies
        such as web beacons and pixel tags on our websites to help us understand
        your product or service preferences and improve customer service.</span
      >
    </p>

    <p style="text-indent: 2em"><span style="font-size: 14px"></span></p>
    <p style="margin-top: 35px">
      <strong><span style="font-size: 14px"></span></strong>
    </p>

    <p>
      <span style="font-size: 14px"
        >(Four)Cloud PlatformSafety Responsibility</span
      >
    </p>

    <p style="text-indent: 2em">
      <span style="font-size: 14px"
        >1,Entropy is responsible for the security management and operation of
        services and data interactions on the Entropy cloud platform, and is
        responsible for the security of the cloud service platform and
        infrastructure provided.APIinterface,SDKFor self-developed software or
        hardware embedded software, customers need to ensure the security and
        compliance of their applications and data, including hardware equipment
        and software, when connected to Enbase Cloud Platform. Enbase Cloud’s
        main responsibility is to develop and operate various basic services,
        platform services and application services, while customers’ main
        responsibility is to customize and develop third-party clients and build
        third-party cloud services based on Enbase Cloud services. The following
        figure shows the shared responsibility model for basic cloud service
        providers, Enbase and customer information security
        responsibilities..</span
      >
    </p> 
      <p style="text-indent: 2em">
      <span style="font-size: 14px"
        >Entropy Cloud's security responsibilities</span
      >
    </p>
    <p>
      <span style="font-size: 14px"
        >a) &nbsp;&nbsp;Enki Cloud selects world-renowned cloud hosting service
        providers such as Amazon, Tencent Cloud, Alibaba Cloud and other
        first-class cloud computing platforms to ensure the security of
        infrastructure and physical equipment for secure management and
        operation.</span
      >
    </p>

    <p>
      <span style="font-size: 14px"
        >b) &nbsp;&nbsp;Entropy Cloud Security covers data security and cloud
        service security. Entropy promises to use its security team and the
        professional attack protection technology experience of world-renowned
        security service providers to provide cloud platform security operation
        and maintenance services, effectively protect the secure operation of
        Entropy Cloud, and ensure the privacy and data security of customers and
        users. Including but not limited to:</span
      >
    </p>

 <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
    </div><span style="font-size: 14px;margin-left: 15px;">Data security: refers to the security management of customers' business data in the cloud business environment, including collection and identification, classification and grading, permissions and encryption, and privacy compliance.</span></p>

    
    <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
    </div><span style="font-size: 14px;margin-left: 15px;">Access control management: management of access rights to resources and data, including user management, permission management, identity authentication, etc.;</span></p>

    <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
        <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
    </div><span style="font-size: 14px;margin-left: 15px;">Cloud service security: refers to the security management of business-related application systems in a cloud computing environment, including the design, development, release, configuration and use of applications and service interfaces.</span></p>
    <p><span style="font-size: 14px">a) &nbsp;&nbsp;When using Entropy Cloud solutions, customers need to strictly follow Entropy's security configuration and access requirements. At the same time, customers need to ensure the security of their own cloud, client or hardware products.</span></p>
<p><span style="font-size: 14px">b) &nbsp;&nbsp;Entropy-basedSDK/APIFor the application software developed, Entropy only provides technical support but cannot provide security guarantee for the software as a whole.</span></p>
<p><span style="font-size: 14px">c) &nbsp;&nbsp;For data security compliance, privacy policy and other related information of customized products based on Entropy solutions, the customer is responsible for the privacy policy statement and legal compliance. When necessary, Entropy's security compliance team is willing to provide assistance and consulting services for security solutions.</span></p><p><span style="font-size: 14px"></span></p>

<p style="margin-top: 35px">
  <strong><span style="font-size: 14px">(five)How we share, transfer and publicly disclose your personal information</span></strong>
</p>

<p style="margin-top: 35px">
    <strong><span style="font-size: 14px">1. Sharing</span></strong>
  </p>

  <p style="text-indent: 2em"><span style="font-size: 14px">We will not share your personal information with any company, organization or individual outside of our company unless we have your explicit consent.</span></p>
  <p style="text-indent: 2em"><span style="font-size: 14px">We may share your personal information with external parties in accordance with laws and regulations or the mandatory requirements of government authorities.</span></p>

  <p style="margin-top: 35px">
    <strong><span style="font-size: 14px">2. Transfer</span></strong>
  </p>
  <p style="text-indent: 2em"><span style="font-size: 14px">We will not transfer your personal information to any company, organization or individual, except in the following circumstances:</span></p>
  <p><span style="font-size: 14px">a) &nbsp;&nbsp;Transfer with explicit consent: We will transfer your personal information to other parties with your explicit consent;</span></p>
  <p><span style="font-size: 14px">b) &nbsp;&nbsp;In the event of a merger, acquisition or bankruptcy liquidation, if personal information is transferred, we will require the new company or organization that holds your personal information to continue to be bound by this personal information protection policy. Otherwise, we will require the company or organization to re-solicit your authorization and consent.</span></p>

  <p style="margin-top: 35px">
    <strong><span style="font-size: 14px">3. Public Disclosure</span></strong>
  </p>
  
  <p style="text-indent: 2em"><span style="font-size: 14px">We will only disclose your personal information in the following circumstances:</span></p>
  <p><span style="font-size: 14px">c) &nbsp;&nbsp;With your explicit consent;</span></p>
  <p><span style="font-size: 14px">d) &nbsp;&nbsp;Disclosure based on law: We may disclose your personal information publicly when required by law, legal process, litigation or government authorities, including:</span></p>

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px;margin-left: 15px;">Related to the personal information controller's performance of obligations prescribed by laws and regulations;</span></strong>
  </p>

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">Directly related to national security and national defense security;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">Directly related to public safety, public health, and major public interests;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">Directly related to criminal investigation, prosecution, trial and execution of judgment;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">For the purpose of protecting the life, property and other major legitimate rights and interests of the personal information subject or other individuals, but it is difficult to obtain the authorization and consent of the individual;/span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">The personal information involved is disclosed to the public by the subject of personal information on his/her own accord;
        Necessary for entering into and performing a contract at the request of the Personal Information Subject;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">Collecting personal information from legally disclosed information, such as legal news reports, government information disclosure, etc.</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">Necessary to maintain the safe and stable operation of the products or services provided, such as discovering and handling product or service failures;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">The controller of personal information is a news organization, and the personal information is necessary for its legitimate news reporting;</span></strong>
  </p>
  

  <p><div class="star-icon" style="width: 10px; height: 10px; position: relative; display: inline-block;">
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(45deg);"></div>
    <div style="content: ''; position: absolute; width: 100%; height: 100%; border: 1px solid black; border-radius: 2px; transform: rotate(-45deg);"></div>
</div>
    <strong><span style="font-size: 14px; margin-left: 15px;">The controller of personal information is an academic research institution. When it is necessary to conduct statistical or academic research for the public interest and when it provides the results of academic research or descriptions to the outside, the personal information contained in the results will be de-identified.</span></strong>
  </p>
  

  
  <p style="text-indent: 2em"><span style="font-size: 14px"></span></p>
<p style="margin-top: 35px">
  <strong><span style="font-size: 14px"></span></strong>
</p>


<p style="margin-top: 25px;text-indent: 2em">
  <strong><span style="font-size: 14px">Please note that according to legal provisions, sharing and transferring de-identified personal information, while ensuring that the data recipient cannot restore and re-identify the personal information subject, does not constitute external sharing, transfer or public disclosure of personal information, and the storage and processing of such data will not require additional notification to you and your consent.</span></strong>
</p>

<p style="margin-top: 35px">
    <strong><span style="font-size: 14px">二、How we protect your personal information</span></strong>
  </p>
  
  <p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">(I) We value the security of personal data. We use appropriate physical, administrative and technical safeguards to protect your personal data from unauthorized access, disclosure, use, modification, damage or loss. For example, we use encryption technology to ensure the confidentiality of data; we use protection mechanisms to prevent data from malicious attacks; we deploy access control mechanisms to ensure that only authorized personnel can access personal data; and we hold security and privacy protection training courses to enhance employees' awareness of the importance of protecting personal data. We will do our best to protect your personal data, but please note that no security measures can be impeccable.</span></p>
  <p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">(II) We will retain your personal data for the period necessary to achieve the purposes described in this policy, unless the retention period needs to be extended or is permitted by law. Because the storage period of data may vary based on different scenarios and products and services, the standards we use to determine the retention period include: the time it takes to retain personal data to complete the business purpose, including providing products and services, maintaining corresponding transaction and business records, controlling and improving product and service performance and quality, ensuring the security of systems, products and services, responding to possible user inquiries or complaints, problem location, etc.; whether the user agrees to a longer retention period; whether there are special requirements for retaining data under laws, contracts, etc. As long as your account is necessary to provide you with services, we will retain your registration information. You can also choose to cancel your account. After you cancel your account, we will stop providing products and services based on the account and delete your corresponding personal data unless there are special legal requirements.</span></p>
  <p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">(III) In the unfortunate event of a personal information security incident, we will inform you in accordance with the requirements of laws and regulations (within 30 natural days at the latest): the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, suggestions for you to prevent and reduce risks on your own, and remedial measures for you, etc. We will inform you of the relevant circumstances of the incident by email, letter, phone, push notification, etc. When it is difficult to inform the subject of personal information one by one, we will take reasonable and effective means to issue an announcement. At the same time, we will also report the disposal of the personal information security incident in accordance with the requirements of the regulatory authorities.</span></p>
  <p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">(IV) The Internet environment is not 100% secure. Although we have these security measures, please note that there are no "perfect security measures" on the Internet. We will do our best to ensure the security of your information.</span></p>
  <p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">(V) To ensure a smooth browsing experience, you may receive content or web links from third parties outside of us and our partners (hereinafter referred to as "third parties"). We have no control over such third parties. You can choose whether to access the links, content, products and services provided by third parties. We cannot control the privacy and data protection policies of third parties, and such third parties are not bound by this Policy. Before submitting personal information to a third party, please refer to the privacy protection policy of the third party.</span></p>
  <p style="text-indent: 2em"><span style="font-size: 14px"></span></p>
<p style="margin-top: 35px">
  <strong><span style="font-size: 14px"></span></strong>
</p>


<p style="margin-top: 35px">
  <strong><span style="font-size: 14px">三、Your rights</span></strong>
</p>
<p style="text-indent: 2em"><span style="font-size: 14px">According to China's relevantlaw, regulations, standards, and common practices in other countries and regions, we guarantee that you can exercise the following rights over your personal information:</span></p>
<p style="margin-top: 35px">
  <strong><span style="font-size: 14px">(一) &nbsp;&nbsp;Accessing your personal information</span></strong>
</p>
<p style="text-indent: 2em"><span style="font-size: 14px">You have the right to access your personal information, except for exceptions provided by laws and regulations. If you want to exercise your right to access data, you can access it yourself in the following ways: Log in to the software platform to view your personal information.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">We will provide you with other personal information generated during your use of our products or services as long as it does not require us to make excessive efforts. If you want to exercise your right to access data,Please send an email to Support@zkdigimax.com</span></p>
<p style="text-indent: 2em">
    <strong><span style="font-size: 14px">Correcting your personal information</span></strong>
  </p>
  
<p style="text-indent: 2em"><span style="font-size: 14px">If you find that the personal information we process about you is wrong, you have the right to ask us to make corrections. You can make a correction request through the methods listed in "(I) Accessing Your Personal Information".</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">If you are unable to correct your personal information through the above link, you can always send an email toSupport@zkdigimax.com</span></p>
<p>
    <strong><span style="font-size: 14px">(二) &nbsp;&nbsp;Deleting your personal information</span></strong>
  </p>
<p style="text-indent: 2em"><span style="font-size: 14px">In the following circumstances, you may submit a written request to us to delete your personal information:</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">1、If our handling of personal information violates laws and regulations;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">2、If we collect and use your personal information without your consent;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">3、If our processing of personal information violates our agreement with you;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">4、If you no longer use our products or services, or you cancel your account;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">5、If we no longer provide you with products or services.</span></p>
<p style="text-indent: 2em; margin-top: 25px;"><span style="font-size: 14px">Where required by applicable law, you also have the right to withdraw your consent at any time when we process your personal data based on your consent. However, the withdrawal of consent will not affect the lawfulness and validity of our processing of your personal data based on your consent before the withdrawal, nor will it affect our processing of your personal data based on other appropriate legitimate bases.</span></p>
<p style="margin-top: 25px">
    <strong><span style="font-size: 14px">(三) &nbsp;&nbsp;Respond to your above request</span></strong>
  </p>
  
<p style="text-indent: 2em"><span style="font-size: 14px">For security reasons, you may need to provide a written request or otherwise prove your identity. We may ask you to verify your identity before processing your request.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">We will 7 daysIf you are not satisfied, you can also complain through the following channels:Support@zkdigimax.com</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">In principle, we do not charge fees for your reasonable requests, but we will charge a certain cost for repeated requests that exceed reasonable limits. We may reject requests that are unreasonably repeated, require too many technical means (for example,require the development of new systems or fundamentally change existing practices), pose risks to the legitimate rights and interests of others, or are extremely impractical (for example, involving information stored on backup tapes).</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">We will be unable to respond to your request in the following circumstances:</span></p>
<p><span style="font-size: 14px">1、Related to the personal information controller's performance of obligations prescribed by laws and regulations;</span></p>
<p><span style="font-size: 14px">2、Directly related to national security and national defense security;</span></p>
<p><span style="font-size: 14px">3、Directly related to public safety, public health, and major public interests;</span></p>
<p><span style="font-size: 14px">4、Directly related to criminal investigation, prosecution, trial and execution of judgment;</span></p>
<p><span style="font-size: 14px">5、The personal information controller has sufficient evidence to show that the personal information subject has subjective malice or abuses his rights;</span></p>
<p><span style="font-size: 14px">6、For the purpose of protecting the life, property and other major legitimate rights and interests of the personal information subject or other individuals, but it is difficult to obtain the consent of the individual;</span></p>
<p><span style="font-size: 14px">7、Responding to the request of the Personal Information Subject will cause serious damage to the legitimate rights and interests of the Personal Information Subject or other individuals or organizations; </span></p>
<p><span style="font-size: 14px">8、Involving trade secrets.</span></p>

<p style="margin-top: 35px">
    <strong><span style="font-size: 14px"></span>四、How do we handle personal information of minors?</strong>
  </p>
  
<p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">Our products, websites and services are primarily intended for adults. Minors should not create their own personal information subject accounts without the consent of their parents or guardians. If you are a minor, we recommend that you ask your parents or guardians to read this policy carefully and use our services or provide us with information only with the consent of your parents or guardians.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">In the case of collecting personal information of minors with parental consent, we will only use or disclose this information when permitted by law, with the explicit consent of the parents or guardians, or when necessary to protect minors.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">If we find ourselves collecting personal information of minors without prior verifiable parental consent, we will try to delete the relevant data as quickly as possible.</span></p>

<p style="margin-top: 35px">
    <strong><span style="font-size: 14px">五、How this Policy is Updated</span></strong>
  </p>
  
<p style="text-indent: 2em;margin-top: 25px;"><span style="font-size: 14px">Our personal information protection and privacy policies may change.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">Without your explicit consent, we will not reduce your rights under this policy. We will post any changes to this policy on this page.</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">For major changes, we will also provide more prominent notices (including for certain services, we will send notices via email to explain the specific changes to the personal information protection policy).</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">Major changes referred to in this policy include but are not limited to:</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">1、Our service model has undergone major changes, such as the purpose of processing personal information, the type of personal information processed, and the way personal information is used;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">2、We have major changes in ownership structure, organizational structure, etc., such as changes in ownership caused by business adjustments, bankruptcy and mergers and acquisitions, etc.;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">3、The main objects of sharing, transfer or public disclosure of personal information have changed;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">4、There are significant changes in your rights to participate in the processing of personal information and how to exercise them;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">5、When our responsible department for handling personal information security, contact information and complaint channels change;</span></p>
<p style="text-indent: 2em"><span style="font-size: 14px">We will also archive previous versions of this policy for your reference.</span></p>
<p style="margin-top: 35px">
    <strong><span style="font-size: 14px">六、How to Contact Us</span></strong>
  </p>
<p style="text-indent: 2em"><span style="font-size: 14px">If you have any questions, comments or suggestions about this Personal Information Protection and Privacy Policy, please send your questions toSupport@zkdigimax.comGenerally, we will respond within 7 days. More contact information will be on our official website（http://www.zkteco.com）On the announcement.</span></p>





  
`,

    user_agree: "ZATA Data Processing Protocol",

    user_agree_text: `<p style="text-align: center;"><strong>data processing protocol</strong></p>
    <p>Version number :<span style=""> V2.0</span></p>
    <p>Date :<span style=""> October,2024</span></p>
    <p><span style="">preamble</span></p>
    <p><span style="">Welcome to ZATA software and services!</span></p>
    <p><span style="">Email: support@zkdigimax.com</span></p>
    <p style="text-indent: 2em"><span style="">Entropy Technologies recognizes the importance of personal data to you and will do
            our best to protect your personal data safely and securely. We are committed to maintaining your trust in us
            and abide by the following principles to protect your personal data: the principle of consistency of rights
            and responsibilities, the principle of clarity of purpose, the principle of opt-in consent, the principle of
            minimum necessity, the principle of ensuring security, the principle of subject participation, the principle
            of openness and transparency, and so on. At the same time, Entropy Base Technology promises that we will
            take appropriate security measures to protect your personal data according to the mature security standards
            in the industry.</span></p>
    <p><span> 一、Scope of application of the agreement</span></p>
    <p style="text-indent: 2em"><span style="">Please make sure that the user reads and fully understands the contents of the
            terms and conditions. Unless the user has read and accepted all the terms and conditions of this agreement,
            the user is not authorized to download, install or use the software and related services. By downloading,
            installing, using the ZATA Software, logging in to your account, etc., you are deemed to have read and
            agreed to be bound by this Agreement.</span></p>

    <p><span style="">I. Scope of application of the agreement</span></p>
    <p style="text-indent: 2em"><span style="">This Agreement is an agreement between the User and Entropy Technology regarding
            the User's download, installation and use of the Software and the use of Entropy Technology's related
            services. "Entropy Base Technology" means Xiamen Entropy Base Technology Co., Ltd. and its related services
            may exist in the operation of the affiliated units, in this Agreement referred to as "we". "User" means the
            individual or organization who registers, logs in, uses the Software and Services and obtains administrative
            privileges, referred to as "You" in this Agreement.</span></p>
    <p><span style="">II. Scope of data processing</span></p>
    <p style="text-indent: 2em"><span style="">In the course of providing the relevant services to you, we may involve the
            collection of personal data, as detailed in the information set out in the table below.</span></p>
    <table style="width: auto;">
        <tbody>
            <tr>
                <td colSpan="1" rowSpan="1" width="182">
                    business scenario
                </td>

                <td colSpan="1" rowSpan="1" width="182">
                    Operational activities
                </td>

                <td colSpan="1" rowSpan="1" width="245">
                    List of data
                </td>
                <td colSpan="1" rowSpan="1" width="245">
                    Type of data subject
                </td>
                <td colSpan="1" rowSpan="1" width="245">
                    Data-processing activities involved and purpose of use
                </td>
            </tr>

            <tr>
                <td colSpan="1" rowSpan="1" width="182">
                    We provide remote technical support to our users.
                </td>
                <td colSpan="1" rowSpan="1" width="182">
                    We may come into contact with users' personal data during the implementation, operation and maintenance of our software.
                </td>
                <td colSpan="1" rowSpan="1" width="182">
                    Username (name/nickname/alias/code), phone number, e-mail address, account password.
                </td>
                <td colSpan="1" rowSpan="1" width="182">
                    subscribers.
                </td>
                <td colSpan="1" rowSpan="1" width="182">
                    Involves the storage, analysis, and deletion of personal data ; used for business information display and analysis, including display, update, data maintenance, personal marking, security traceability, business requirements, research, basic maintenance, account management.
                </td>
            </tr>
        </tbody>
    </table>
    <p><span style="">III.  Definitions</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">Unless otherwise agreed by the parties, the definitions of terms used in this Agreement are set forth below in alphabetical order.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(i) "Data Subject" (Data Subject) means an identified or identifiable natural person. An identifiable natural person is an individual who can be identified directly or indirectly, in particular by reference to an identifier, which includes, for example, a name, an identity card number, location data, an online identifier or one or more elements of physical, physiological, genetic, mental, economic, cultural or social identity specific to that natural person.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(ii) "EU Standard Contractual Clauses" ("EU Standard Contractual Clauses") means the standard contractual clauses set out in Article 44 of the European Union Act No. 79 of 2016 onwards (and in accordance with Article 26(2) of the EU Directive 95/46/EC) relating to the transfer of personal data to a Processor established in a third party country/region that is not in a position to ensure adequate data protection. The relevant standard contractual clauses for the transfer of personal data to a processor established in a third party country/territory where adequate data protection cannot be ensured, which are published on the relevant European Commission website and may be modified or updated from time to time.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(iii) "Personal Data" ("Personal Data") means any information relating to an identified or identifiable natural person ("Data Subject").</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(iv) "Personal Data Breach" ("PDB") means a security breach in accordance with local data protection regulations, including, but not limited to, the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(v) "Data Protection Laws" (Privacy Laws) means any potentially applicable laws and/or regulations relating to the security and protection of personal data (such as the GDPR or other nationally applicable privacy protection laws), any laws and regulations implemented or enacted pursuant thereto, as well as amendments to such laws and regulations , updates or re-enactments thereof.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(vi) "Regulatory Bodies" means government departments, regulators, statutory bodies and other agencies that have the power to regulate, investigate or influence matters relating to data security and the protection of personal data and privacy under statutes, rules, regulations, codes of conduct or other documents.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(vii) "Services" (Service) means the services involving the processing of personal data provided by Entropy Base Technology in accordance with specific service agreements and other contractual texts concluded with users.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(viii) "Sub-processor" means an organization or individual involved in the processing of personal information other than Entropy-based Technology and related groups.</span></p>   
   <p><span style="">IV. Data-processing requirements</span></p>
    <p style="text-indent: 2em"><span style="">(i) Data-processing principles</span></p>

    <p style="text-indent: 2em"><span style=" text-indent: 18pt">We process personal data in accordance with the general principles of lawfulness, legitimacy, transparency, purpose limitation, data minimization, accuracy, minimization of storage period, integrity and confidentiality, and accountability, and we will carry out our data processing activities in accordance with your instructions, but not beyond the purposes and scope of your instructions and this agreement, and will not use personal data for any other purpose. We will not use the personal data for any other purpose. We will notify you immediately if we believe that your instructions violate applicable data protection regulations. We will fully support your right to data subject protection, such as access, rectification and deletion.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">We will take the clock source configuration provided by the cloud service provider as the basis, and will notify you by email in the first instance if there is any change in the service mode/service content of our products.</span></p>
    <p style="text-indent: 2em"><span style="">(ii) Processing of special categories of personal data</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">We will not process any personal data that may reveal racial or ethnic origins, political opinions, or membership of trade unions in the industry, the only biodata that identifies a natural person, in a manner that violates applicable data protection regulations.</span></p>

    <p style="text-indent: 2em"><span style="">(iii) Cross-border transfer of personal data</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">Does not involve cross-border processing of personal data.</span></p>
    <p style="text-indent: 2em"><span style="">(iv) Data security</span></p>
    <p><span style=" text-indent: 18pt">During the processing of personal data, we take effective technical and organizational measures in accordance with applicable data protection regulations and your requirements to ensure that personal data that we may come into contact with during the data processing process are kept confidential by adopting confidentiality measures not lower than the level of confidentiality of our own information, and are not to be disclosed to any third party without your written permission, so as to protect the personal data from disclosure.</span></p>
    <p style="text-indent: 2em"><span style="">(v) Emergency response mechanism for data leakage incidents</span></p>
    
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">1. Upon discovery of a personal data breach, we will send you a written notice within seventy-two (72) hours to fulfill our obligation to notify the supervisory authority and the data subject in accordance with the provisions of the applicable data protection legislation. Such notice contains the following:</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(1) Summarize the impact of the personal data breach, including, but not limited to, a description of the nature of the personal data breach, the categories and number of relevant data subjects, and the relevant personal data processing records.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(2) Possible risks and consequences of a personal data breach.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(3) Measures taken or proposals made by us to deal with personal data breaches.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(4) the name and contact details of our Data Protection Officer, Privacy Officer and any relevant contacts who can provide details.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(5) any other information reasonably requested by you in connection with a personal data breach.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(6) If the above information cannot be provided at the same time, it shall be provided in stages over a period of thirty-six (36) hours or within the time period requested by you.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">2. We will immediately investigate, identify, prevent and minimize the impact of any personal data breach that occurs and, with your prior consent, take the necessary remedial measures.</span></p>
    <p style="text-indent: 2em"><span style="">(vi) Introduction of subprocessors</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">1. We will not subcontract or outsource any personal data processing operations to sub-processors without your permission.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">2. In accordance with the applicable legal requirements, we shall enter into a data processing agreement with the sub-processor and do our due diligence. We shall pass on your request to the sub-processor and shall be liable for any negative impact or adverse consequences resulting from the data processing behavior of the sub-processor or any third party designated by it.</span></p>
 
    <p><span style="">(vii) Deletion of personal data.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">Unless requested to the contrary or otherwise provided for by data protection legislation, at your request we will delete the relevant personal data as soon as we have fulfilled our obligations as agreed in the applicable agreement between the parties and certify to you that it has fulfilled such obligations.</span></p>
    <p><span style="">(viii) Responsibility for cloud platform security</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">1、Entropy Base is responsible for the security management and operation of services and data interaction on Entropy Base Cloud Platform, and is responsible for the security of the cloud service platform and infrastructure provided. Customers develop their own software or hardware embedded software by calling API interface, SDK, and accessing Entropy Base Cloud Platform need to guarantee the security compliance of their applications and data, including hardware equipment and software by themselves. The main responsibility of Entropy Base Cloud is to develop,operate and maintain the basic services, platform services and application services, while the main responsibility of the customer is to customize the third-party client and build the third-party cloud services on top of using Entropy Base Cloud services. The following figure shows the shared responsibility model of the basic cloud service provider, Entropy Base and the customer's information security responsibility.</span></p>
    <table style="width: 100%; height: 100vh; border-collapse: collapse">
    <tr>
      <th
        rowspan="11"
        style="
          border: 1px solid black;
          text-align: center;
          writing-mode: vertical-rl;
          background-color: #f4f4f4;
          height: 100vh;
        "
      >
        Security Operation Management
      </th>
      <th
        colspan="3"
        style="
          border: 1px solid black;
          text-align: center;
          background-color: #f4f4f4;
        "
      >
        Rongji Cloud Platform, Software, Embedded Software
      </th>
      <th
        rowspan="12"
        style="
          border: 1px solid black;
          text-align: center;
          writing-mode: vertical-rl;
          background-color: #f4f4f4;
          height: 100vh;
        "
      >
        Authentication&nbsp;&nbsp;/ Access Control&nbsp;&nbsp;/ Permissions Control
      </th>
    </tr>

    <!-- Second row, six cells divided into two rows and three columns -->
    <tr>
      <td style="border: 1px solid black; text-align: center">
        Third-Party Client
      </td>
      <td style="border: 1px solid black; text-align: center">
        Hardware / Embedded Software
      </td>
      <td style="border: 1px solid black; text-align: center">Third-Party Cloud</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: center">API / SDK</td>
      <td style="border: 1px solid black; text-align: center">Module / SDK</td>
      <td style="border: 1px solid black; text-align: center">API / SDK</td>
    </tr>

    <!-- Third row, highlighted background -->
    <tr style="background-color: #e9f5e9">
      <td colspan="3" style="border: 1px solid black; text-align: center">
        Rongji Cloud
      </td>
    </tr>
    <!-- Fourth row -->
    <tr>
      <td style="border: 1px solid black; text-align: center">
        Identity Verification Management
      </td>
      <td style="border: 1px solid black; text-align: center">Entrance/Exit Management</td>
      <td style="border: 1px solid black; text-align: center">Office Management</td>
    </tr>
    <!-- Fifth row, highlighted background -->
    <tr style="background-color: #e9f5e9">
      <td colspan="3" style="border: 1px solid black; text-align: center">
        Data Services
      </td>
    </tr>
    <!-- Sixth row -->
    <tr>
      <td style="border: 1px solid black; text-align: center">Storage</td>
      <td style="border: 1px solid black; text-align: center">Database</td>
      <td style="border: 1px solid black; text-align: center">Data Isolation</td>
    </tr>
    <!-- Seventh row -->
    <tr>
      <td style="border: 1px solid black; text-align: center">Log Service</td>
      <td style="border: 1px solid black; text-align: center">Data Analysis</td>
      <td style="border: 1px solid black; text-align: center"></td>
    </tr>
    <!-- Eighth row, highlighted background -->
    <tr style="background-color: #e9f5e9">
      <td colspan="3" style="border: 1px solid black; text-align: center">
        Infrastructure
      </td>
    </tr>
    <!-- Ninth row -->
    <tr>
      <td style="border: 1px solid black; text-align: center">Tencent Cloud</td>
      <td style="border: 1px solid black; text-align: center">AWS</td>
      <td style="border: 1px solid black; text-align: center">Alibaba Cloud</td>
    </tr>
    <!-- Tenth row, highlighted background -->
    <tr style="background-color: #e9f5e9; border: 1px solid black">
      <td style="border: 1px solid black; text-align: center">
        Rongji and Infrastructure Shared Responsibility
      </td>
      <td style="border: 1px solid black; text-align: center">Rongji Cloud Responsibility</td>
      <td style="border: 1px solid black; text-align: center">Customer Responsibility</td>
    </tr>
  </table>
    <p style="text-indent: 2em"><span style="">2、Security responsibility of entropy-based cloud</span></p>
  
    <p style="text-indent: 2em"><span style=" text-indent: 18pt; ">(1) Entropy Base Cloud ensures the safety of infrastructure, physical equipment for security management and operation by selecting world-renowned cloud hosting providers such as Amazon, Tencent Cloud, Aliyun and other first-class cloud computing platforms.</span></p>
    <p style="text-indent: 2em"><span style="text-indent: 18pt;  ">(2) Entropy Cloud security covers data security and cloud service security. Entropy Base promises to utilize its security team as well as the professional attack protection technology experience of well-known security service vendors in the global scope to provide security operation and operation services of the cloud platform, to effectively protect the security operation of Entropy Base Cloud, as well as to safeguard the security of customers, users' privacy and data. Including but not limited to:</span></p>
    <p style="text-indent: 2em"><span style="">a. Data Security: This refers to the security management of the customer's business data itself in the cloud business environment, including collection and identification, classification and hierarchy, permissions and encryption, and privacy compliance;</span></p>
    <p style="text-indent: 2em"><span style="">b. Access control management: management of access rights to resources and data, including user management, rights management, authentication, etc;</span></p>
    <p style="text-indent: 2em"><span style="">c. Cloud service security: refers to the security management of business-related applications in a cloud computing environment, including the design, development, release, configuration and use of applications and service interfaces.</span></p>
    <p style="text-indent: 2em"><span style="">3. Client's responsibility for security</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(1) Customers need to strictly follow the security configuration and access requirements of Entropy Base when using Entropy Base Cloud's solutions. At the same time, customers need to ensure the security of their own cloud, client or hardware products themselves.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(2) For the application software developed based on Entropy Base SDK/API, Entropy Base only provides technical support, but is unable to provide security for the software as a whole.</span></p>
    <p style="text-indent: 2em"><span style=" text-indent: 18pt">(3) For the data security compliance, privacy policy and other related information of the customized products based on Entropy Base Solution, the privacy policy statement and legal compliance are the responsibility of the customer, and when necessary, Entropy Base Security Compliance Team is willing to provide security solution help and consulting services.</span></p>

    <p><span style="">(ix) Data backup</span></p>
    <p style="text-indent: 2em"><span style="">In order to prevent data loss due to hardware failure, natural disasters and other various unforeseen reasons, we will regularly back up the cloud database and keep it consistent with (d) data security. The backup frequency is one backup per day, and the backup data is encrypted and stored, and at the end of the service within 7 days to delete your relevant backups in accordance with the law.</span></p>
    <p><span style="">V. Responsibility</span></p>
    <p style="text-indent: 2em"><span style="">You, as the data controller, are responsible for compliance with applicable data protection regulations. You are obliged to assess the legality of the processing of personal data stored on the software. You shall ensure that any disclosure of personal data to us is lawful.</span></p>
    <p><span style="">vi. right to audit</span></p>
    <p style="text-indent: 2em"><span style=" ">You may review our compliance with the terms of the Agreement and this Data Processing Agreement once a year.</span></p>
    <p><span style="">vii. applicable law</span></p>
    <p style="text-indent: 2em"><span style="">This Agreement will be governed by the laws of the Member State in which the User is located.</span></p>
    `,
  },
  PCS111: "ZATA User Agreement",
  PCS112: "Please check and read the agreement",
  PCS113: "and",
  PCS114: "Please Select the Outlet...",
  PCS115: "Empty Template",
  PCS116: "Please select whether the product template is empty.",
  PCS117: "No device was queried.",
  PCS118: "Whether the product template is empty?",
  PCS119: "Please enter the name of the store.",
  PCS120: "The expired date cannot be empty",
  PCS121: "Professional",
  PCS122: "The subscription start time cannot be empty.",
  PCS123: "End date must not be earlier than start date.",
  PCS124:
    "You do not have permission for this function, please contact the administrator.",
  PCS125: "Please enter last name",
  PCS126: "Please enter your name",
  PCS127: "Please enter the user ID",
  PCS128: "Please select the join date",
  PCS129: "enter your PIN",
  PCS130: "Please  Enter confirm password",
  PCS131: "Two different password entries",
  PCS132: "Subscription Records",
  PCS133: "Adult",
  PCS134: "Kids",
  PCS135: "Adult & Kids",
  PCS136: "PERVIOUS PERIOD",
  PCS137: "Adult Rate",
  PCS138: "Kids Rate",
  PCS139: "Pass By",
  PCS140: "Enter",
  PCS141: "Leave",
  PCS142: "Entry Rate",
  PCS143: "Previous Rate",
  PCS144: "Create Client",
  PCS145: "Select Client",
  PCS146: "",
  PCS147: "",
  PCS148: "",
  PCS149: "",
  PCS150: "",
  PCS151: "",

  tenant: {
    tenant_mangerment: "Tenant Management",
    tenantId: "Tenant ID",
    phone: "Mobile",
    info: "Company Introduction",
    address: "Company Address",
    account: "Account Email",
    enter_company_name: "Please input the Company Name",
    enter_account: "Please enter your account (email)",
    enter_info: "Please enter company profile",
    enter_address: "Please enter your company address",
    enter_phone: "Please enter your mobile",
    addTenant: "Add Tenant",
    editTenant: "Edit Tenant",
    enter_address_required: "Company address is mandatory",
  },

  system: {
    system_rule_password_length:
      "The Length of Password should be in between 6 to 30 characters",
    system_rule_email: "Please enter the correct email address",
    system_about: "About",
  },

  menu: {
    package_subscription: "Package Subscription",
    subscription: "Subscription",
    subscription_records: "Subscription Records",
  },

  subscription: {
    Update: "Update",
    extend: "Extend",
    more: "More Device",
    subscription: "Subscription",
    trial_package: "Trial",
    advanced_package: "Advanced",
    starter_package: "Starter",
    professional_package: "Professional",
    summit_package: "Summit",
    includes: "Includes",
    free: "Free",
    freeTip: "Free for 2 devices for 15 days",
    getStarted: "Get Started",
    comingSoon: "Coming Soon",
    customerName: "Customer Name",
    enterCustomerName: "Enter Customer Name",
    customerAccount: "Customer Account",
    accountCreatedBy: "Account Created By",
    contractID: "Contract ID",
    enterContractID: "Enter Contract ID",
    contractAmount: "Contract Amount",
    enterContractAmount: "Enter Contract Amount",
    subscriptionPackage: "Subscription Package",
    subscriptionCreationDate: "Subscription Creation Date",
    expirationDate: "Expiration Date",
    activeDate: "Activation Date",
    selectExpirationDate: "Select Expiration Date",
    selectActiveDate: "Select Activation Date",
    numberOfDevice: "Number of Devices",
    enterNumberOfDevice: "Enter Number of Devices",
    accountInformation: "Account Information",
    customerEmailAddress: "Customer Email Address",
    enterCustomerEmailAddress: "Enter Customer Email Address",
    selectCustomer: "Select a Customer",
    area: "Area",
    enterArea: "Please Enter a Area Name",
    mobileNumber: "Mobile Number",
    enterMobileNumber: "Enter Mobile Number",
    contractInformation: "Contract Information",
    amountUnit: "Amount Unit",
    selectAmountUnit: "Select Amount Unit",
    contractFile: "Contract File",
    uploadContract: "Upload Contract",
    uploadCompanyLogo: "Upload Company Logo",
    submit: "Submit",
    emailAddress: "Email Address",
    clickSelectCustomer: "Click on the input box to select the Customer",
    SelectCustomer: "Select a Customer",
    createCustomer: "Create a Customer",
    modifyTitle: "Modify Subscription",
    positive: "Please enter a positive integer",
    tipMsg:
      "The number of devices exceeds the package limit, please delete devices and refresh the page first",
    tipMsg2:
      "The number of devices exceeds the package limit, please contact the administrator",
    tipMsg3:
      "You do not have menu permissions, please contact the administrator",
    payment: "payment",
    paymentMsg: "Are you sure to pay for the current order?",
    singleMaterial: "Single content",
    US$4: "US$4",
    US$10: "US$10",
    US$10: "US$10",
    basic_package_tip: "per device/month(billed annually) US$11 billed monthly",
    areaNameTip:
      "Must start with a letter or Chinese character and can only contain letters, numbers, Chinese characters, spaces, and underscores",
    payStatus: "Pay Status",
    paid: "Paid",
    unpaid: "Unpaid",
    lengthTip: "The length must not exceed {{length}}.",
    minLengthTip: "The length must be greater than {{length}}.",
    moreThen: "The amount cannot be negative.",
    numberMoreThen: "The amount cannot exceed {{number}}.",
    singleFile: "Only a single file is allowed for upload.",
    dropTip: "Drag and drop here to upload.",
    limtFileSize: "The file size cannot exceed {{tip}}.",
    Account_Information: "Account Information",
    contract_information: "Contract Information",
    enter_Dev_number: "Please enter the number of devices",
    clinet_name: "Customer Client Name",
    please_enter_clinet_name: "Please enter Client Name",
    customer_email_address: "Customer Email Address",
    please_enter_email_address: "Please Enter Customer Email Address",
    email_formatted: "The email is not formatted correctly",
    enter_customer_code: "PLease Enter Customer Code",
    client_Code: "Client Code",
    please_select_location: "Please select location.",
    please_enter_first_name: "Please Enter First Name",
    please_enter_last_name: "Please Enter Last Name",
    please_select_country: "Please Select Country",
    please_select_province: "Please Select Province ",
    please_select_city: "Please Select City",
    please_formatted_phone: "Please enter the correct mobile phone number",
    only_number: "Only numbers may be entered",
    Monthly: "Monthly",
    Annually: "Annually",
    extend_months: "Extend Months Of Subscribe",
    extend_years: "Extend Years Of Subscribe",
    subMonths: "Months Of Subscribe",
    subAnnually: "Years Of Subscribe",
    please_enter_number_months: "Please Enter Number of Months",
    please_enter_number_Annually: "Please Enter Number of Years",
    please_subscription_number:
      "Please enter the monthly/annual number of subscriptions",
    please_select_zoonTime: "Please select time zone",
    please_select_startTime: "Please select a start time",
    please_select_endTime: "Please select a end time",
    Contract_ID: "Contract ID",
    please_enter_contract_Id: "Please Enter Contract ID",
    please_enter_contract_amount: "Please Enter Contract Amount",
    please_enter_contract_unit: "Please Select Contract Unit",
    Contract_Amount: "Contract Amount",
    Contract_Unit: "Contract Unit",
    Device_Count: "Device Count",
    subCreatedAt: "subCreatedAt",
    select_retail_clinet: "Please select a Customer",
  },

  about: {
    media_personal_about: "About",
    common_system_version: "Version",
    base_system_browsers: "Recommended Browser",
    base_system_resolution: "Display Resolution",
    common_system_resoltion_recommond:
      "Suggested screen resolution: 1920x1080 or higher",
    common_package_details: "Package Details",
    common_remaining_day: "Remaining Valid Days (days)",
    common_no_limit: "No Limit",
    common_used_day: "Used: {{day}} days",
    common_gross: "Total",
    common_day: "days",
    common_remaining_device_count: "Remaining Device Count (units)",
    common_add_device_num: "Added: {{count}} units",
    common_add_device_dot: "units",
    common_deviceType: "Device type",
    common_please_type: "Please select the device type",
  },
};

export default en;
