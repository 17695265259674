import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip, Grid, Box, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RingLoader from "react-spinners/RingLoader";
import { useSnackbar } from "notistack";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import ConfirmModal from "../../components/ConfirmModel";
import ListLayout from "../../components/ListLayout";
import {
  REACT_OUTLET_ADD,
  REACT_OUTLET_EDIT,
  REACT_OUTLET_VIEW,
} from "@/router/ReactEndPoints";
import { deleteClientById } from "../../services/CompanyService";
import { deleteOutlet, getOutletList } from "../../services/OutletService";
import CommonUtil from "../../util/CommonUtils";
import AccessControl from "../../components/AccessControl";
export default function Outlet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [storeName, setStoreName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
  };

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    setIsLoading(true);
    try {
      const { pageNumber, pageSize } = filters;
      getOutletList({ pageNumber, pageSize }).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const delteItem = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("LVLLOC0004")}
            onConfirm={() => {
              deleteClientById(id).then((response) => {
                if (response.data.code === "LVLI0002") {
                  enqueueSnackbar(response.data.message, {
                    variant: "success",
                  });
                  loadData();
                } else {
                  enqueueSnackbar(response.data.message, {
                    variant: "error",
                  });
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: "clientName",
      headerName: t("LVLOT0002"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.clientName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.clientName)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: t("LVLOT0003"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "outletType",
      headerName: t("LVLOT0004"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.outletTypeName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.outletTypeName)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "country",
      headerName: t("LVLOT0005"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.countryName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.countryName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "state",
      headerName: t("LVLOT0006"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.stateName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.stateName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "city",
      headerName: t("LVLOT0007"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.cityName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.cityName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "addressLine",
      headerName: t("LVLDAC0022"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.addressLine} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.addressLine)}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "cityZone",
    //   headerName: t("LVLOT0008"),
    //   flex: 1,
    //   renderCell: (e) => (
    //     <Tooltip title={e.row.cityZone} arrow placement="bottom">
    //       <span>{CommonUtil.formatLongText(e.row.cityZone)}</span>
    //     </Tooltip>
    //   ),
    // },
    // {
    //   field: "timeZone",
    //   headerName: t("LVLOT0009"),
    //   flex: 1,
    //   renderCell: (e) => (
    //     <Tooltip
    //       title={CommonUtil.getTimeZoneNameByValue(e.row.address?.timeZone)}
    //       arrow
    //       placement="bottom"
    //     >
    //       <span>
    //         {CommonUtil.formatLongText(
    //           CommonUtil.getTimeZoneNameByValue(e.row.address?.timeZone)
    //         )}
    //       </span>
    //     </Tooltip>
    //   ),
    // },
    {
      headerName: t("LVLOT0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          <Tooltip title={t("LVLDB0020")} arrow>
            <VisibilityIcon
              onClick={() => handleActions("View", e)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                fontSize: "16px",
                color: "#A2A3A3",
              }}
            />
          </Tooltip>

          <AccessControl authorization={"1014"}>
            <Tooltip title={t("LVLDB0021")} arrow sx={{ marginLeft: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          </AccessControl>
          <AccessControl authorization={"1015"}>
            <Tooltip title={t("LVLDB0022")} arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Delete", e)}
              />
            </Tooltip>
          </AccessControl>
        </IconHandaler>
      ),
    },
  ];

  const handleDelete = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("LVLDAC0029")}
            onConfirm={() => {
              deleteOutlet(data.row.id, data.row.companyId).then((response) => {
                if (response?.data?.code === "LVLI0006") {
                  enqueueSnackbar(t("LVLOT0020"), {
                    variant: "success",
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top",
                    },
                    style: {
                      marginTop: "300px",
                    },
                  });
                  loadData();
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      sessionStorage.setItem("editOutlet", JSON.stringify(data.row));
      navigate(REACT_OUTLET_VIEW + data.id);
    } else if (action === "Edit") {
      sessionStorage.setItem("editOutlet", JSON.stringify(data.row));
      navigate(REACT_OUTLET_EDIT + data.id);
    } else if (action === "Delete") {
      delteItem(data.id);
      //   deviceList({ ...outletParam, siteId: data.row.id }).then((response) => {
      //     if (response?.data?.code === "DMSI0000") {
      //       enqueueSnackbar("outlet mapped to device can not be deleted", {
      //         variant: "Error",
      //         anchorOrigin: {
      //           horizontal: "center",
      //           vertical: "top",
      //         },
      //         style: {
      //           marginTop: "300px",
      //         },
      //       });
      //       return false;
      //     } else {
      //       handleDelete(data);
      //     }
      //   });
    }
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const getRowId = (data) => data.id;

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      getOutletList({
        pageNumber: 1,
        pageSize: filters.pageSize,
        name: storeName,
      }).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const toAdd = () => {
    navigate(REACT_OUTLET_ADD);
  };
  const refreshClick = () => {
    loadData();
  };
  const handleReset = () => {
    setStoreName("");
    loadData();
  };
  return (
    <ListLayout navigateBack={false} title={t("LVLOT0022")}>
      <Grid
        display={"flex"}
        sx={{
          width: "100%",
          background: "#FFF",
          height: "100px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLDB0019")}
            placeholder={t("PLACE01")}
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          ></TextField>
        </Box>

        <Box sx={{ margin: "35px 35px" }}>
          <Button
            variant="contained"
            sx={{ marginLeft: 1, p: 0.5 }}
            style={{
              height: "28px",
              fontWeight: 400,
              fontSize: "14px",
              textTransform: "none",
            }}
            onClick={handleSubmit}
          >
            {t("LVLGF0009")}
          </Button>

          <Button
            style={{
              height: "28px",
              fontWeight: 400,
              fontSize: "14px",
              textTransform: "none",
            }}
            sx={{ marginLeft: 1, p: 0.5 }}
            variant="outlined"
            onClick={handleReset}
          >
            {t("LVLGF0008")}
          </Button>
        </Box>
      </Grid>

      <Grid display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        <AccessControl authorization={"1013"}>
          <Box
            item
            pr={2}
            onClick={() => {
              toAdd();
            }}
          >
            <AddIcon width={"35"} height={"35"} className="pointer" />
          </Box>
        </AccessControl>

        <Box item pr={2}>
          <RefreshIcon
            width={"35"}
            height={"35"}
            className="pointer"
            onClick={refreshClick}
          />
        </Box>
      </Grid>

      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={override}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <DataTable
          height="auto"
          columns={columns}
          rows={records || []}
          getRowId={getRowId}
          page={filters.pageNumber - 1}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onSelection={() => console.log()}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
          checkboxSelection={false}
        />
      )}
    </ListLayout>
  );
}
