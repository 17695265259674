import {
  FormHelperText,
  InputLabel,
  OutlinedInput,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import RequirePoint from "./RequirePoint";
import { ReactComponent as VisibilityIcon } from "@/assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "@/assets/images/icon_viewon.svg";
import { useState } from "react";

const CmpInput = (props) => {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height,
    inputType,
    fontSize = "16px",
    onClickInput,
    selectOrCreate,
    ...orther
  } = props;

  const [confirmVisibility, setConfirmVisibility] = useState(true);

  const blurFn = (e) => {
    if (formik?.handleBlur) {
      formik?.handleBlur(e);
    }
    if (handleChange) {
      handleChange();
    }
  };
  const changeFn = (e) => {
    if (formik?.handleChange) {
      formik?.handleChange(e);
    }
    if (handleBlur) {
      handleBlur(e);
    }
    if (handleChange) {
      handleChange(e);
    }
  };

  return (
    <Stack spacing={1}>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === "left" ? "12px" : "",
            width: width,
            fontSize: fontSize,
          }}
          htmlFor={"CmpInput_" + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>
        <Stack
          sx={{
            flexGrow: 1,
            width: "100%",
          }}
        >
          <OutlinedInput
            id={"CmpInput_" + name}
            type={confirmVisibility ? inputType || "text" : "text"}
            autoComplete={inputType === "password" ? "new-password" : "off"}
            value={formik?.values[name]}
            name={name}
            sx={{ height: "38px" }}
            onBlur={blurFn}
            onChange={changeFn}
            onClick={selectOrCreate ? onClickInput : undefined}
            placeholder={placeholder}
            fullWidth
            error={Boolean(
              (formik?.touched[name] && formik?.errors[name]) || error
            )}
            endAdornment={
              <Box display="flex">
                {isClear &&
                  formik?.values[name] !== "" &&
                  formik?.values[name] !== undefined &&
                  !disabled && (
                    <IconButton
                      sx={{}}
                      onClick={(e) => {
                        if (formik) {
                          formik.setFieldValue(name, "");
                        }
                        e.stopPropagation();
                      }}
                    >
                      <ClearIcon
                        fontSize="small"
                        sx={{
                          color: "#757575",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                {inputType === "password" &&
                  (confirmVisibility ? (
                    <IconButton>
                      <VisibilityOffIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setConfirmVisibility(!confirmVisibility);
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setConfirmVisibility(!confirmVisibility);
                        }}
                      />
                    </IconButton>
                  ))}
              </Box>
            }
            disabled={disabled}
            {...orther}
          />
          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CmpInput;
